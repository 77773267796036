.filter-container {
    display: flex;
    flex-direction: column;
    background: #F6F8F9;
    border-radius: 6px;
    padding: 32px;
    margin-bottom: 24px;
}

.filters-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */
    letter-spacing: -0.014em;
    /* Dark Gray / Dark Gray 2 */
    color:#252C32;
}

.filter-data {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    flex-shrink:1;
    gap: 0px 12px;
}

.filter-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 12px 12px 12px 0px;
}

.filter {
    margin-left: 6px;
    margin-right: 6px;
}

.filter-name {
    left:-10px;
    padding-bottom: 4px;
    color: #252C32;
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.176px;
}

.filter-name-user {
    padding-bottom: 4px;
    color: #252C32;
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.176px;
}

.filter-name-status {
    margin-left: 6px;
    padding-bottom: 4px;
    color: #252C32;
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.176px;
}

.filter-name-request {
    margin-left: 6px;
    padding-bottom: 4px;
    color: #252C32;
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.176px;
}

.filter-item {
    margin-left:  0px !important;
    margin-right: 6px !important;
    padding: 8px 16px !important;
    border-radius: 4px;
    border: 1px solid var(--light-gray-light-gray-2, #DDE2E4);
    background: var(--black-white-white, #FFF);
    height:38px;
    width:150px;
}

    .filter-item:not(.text-field__input-error):focus {
        color: #212529;
        background-color: #fff;
        border-color: #8CEEE6;
        font-size: 16px;
        outline: 0;
    }

    .filter-item:hover {
        border-radius: 6px;
        border: 1px solid var(--mid-gray-mid-gray-5, #B0BABF);
        background: var(--black-white-white, #FFF);
    }

.filter-item-user {
    margin-left: 0px !important;
    margin-right: 6px !important;
    border-radius: 6px;
    /* border: 1px solid var(--light-gray-light-gray-2, #DDE2E4); */
    background: var(--black-white-white, #FFF);
}


.filter-item-status {
    margin-left: 6px !important;
    margin-right: 6px !important;
    border-radius: 6px;
    /* border: 1px solid var(--light-gray-light-gray-2, #DDE2E4); */
    background: var(--black-white-white, #FFF);
}

.filter-item-request {
    margin-left: 6px !important;
    margin-right: 6px !important;
    border-radius: 6px;
    /*  border: 1px solid var(--light-gray-light-gray-2, #DDE2E4); */
    background: var(--black-white-white, #FFF);
}

.filter.period {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    margin:0px;
}

    .filter.period > .filter-select-period {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
}

.react-datepicker__day--keyboard-selected {
    background-color: #1AC19D !important;
    color:white;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #1AC19D !important;
    color: white;
}
.react-datepicker__close-icon::after {
    margin-left:5px;
    cursor: pointer;
    background-color: #1AC19D;
    color: gray;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "X";
}

.react-datepicker__close-icon {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0 6px 0 0;
    position: absolute;
    top: 0;
    right: 3px;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}
.filter-info-block{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
}
/*.react-datepicker__close-icon::after {

}*/

/*.filter-item .react-datepicker__day--keyboard-selected {
    background-color: red !important;
}*/

/*        .filter.period > .filter-select-period :first-child {
            margin-left: 0px !important;
        }

        .filter.period > .filter-select-period :last-child {
            margin-right: 0px !important;
        }*/

.css-13cymwt-control { /*default*/
    outline:none!important;
    border: 1px solid #DDE2E4!important;
}

    .css-13cymwt-control:hover {
        border: 1px solid #9AA6AC!important;
        outline:none!important;
    }

.css-t3ipsp-control {
    box-shadow: none !important;
    border: 1px solid #8CEEE6!important;
    outline:none!important;
}
.button-cancel {
    display: flex;
    border: 1px solid #DDE2E4;
    border-radius: 6px;
    text-align: center;
    align-items: center;
    padding: 10px 16px;
    background-color: white;
}
.button-cancel:hover {
    background-color: #DDE2E4;
}