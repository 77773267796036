.container-share-copy-tg {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    width: 100%;
}
.button-share-tg {
    display: flex;
    padding: 10px 16px;
    background-color: white;
    border-radius: 6px;
    width: 100%;
    text-align: center;
    justify-content: center;
}
.container-share-text-tg {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
.button-copy-tg {
    display: flex;
    background-color: white;
    padding: 10px 16px;
    border-radius: 6px;
}
.btn-share-tg {
    background-color: white;
    border: none;
}
/* .show-message-tg {
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 12px;
    border: 1px solid var(--Dark-Gray-Dark-Gray-4, #3C464E);
    background: var(--Dark-Gray-Dark-Gray-3, #303940);
    box-shadow: 0px 0px 1px 0px rgba(26, 32, 36, 0.32), 0px 40px 64px 0px rgba(91, 104, 113, 0.24);
    color: #fff;
} */
 .custom-popover {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    gap: 12px;
    z-index: 50;
}

    .custom-popover > .popover-text {
        font-size: 16px;
        padding: 16px;
        border-radius: 16px;
        margin-top: 50px;
        background: rgba(0, 0, 0, .6) !important;
        color: #ffffff !important;
        z-index: 100;
        text-align: center;
    }
.referal-text {
    color: var(--black-white-black, var(--Colors-Monochrome-1000, #000));
    /* Headings / Heading 18 Semi Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.252px;
}
.referal-more-text {
    color: var(--Mid-Gray-Mid-Gray-2, #6E7C87);
    /* UI Small / UI Text 12 Reg */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}