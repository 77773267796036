.ticket-table-container {
    display: flex;
    flex-direction: column;
/*    border: 1px solid gray;*/
    border-radius: 6px;
}

    .ticket-table-container > div {
        display: flex;
        flex-direction: row;
/*        border-bottom: 1px solid #E1E1E1;*/
    }

    .ticket-table-container > div {
        text-align: left;
    }

        .ticket-table-container > div > .id-clients {
            flex: 0.5;
        }

        .ticket-table-container > div > .client-fio {
            flex: 1.3;
        }

        .ticket-table-container > div > .client-username {
            flex: 1;
        }

        .ticket-table-container > div > .client-phone{
            flex: 1;
/*            text-align: left !important;*/
        }

        .ticket-table-container > div > .client-tickets {
            flex: 0.6;
        }

       

    .ticket-table-container > .ticket-table-header {
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        /*        background: #2b2927;*/
        border-bottom: 1px solid #E5E9EB;
        color: #6E7C87;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.176px;
    }

    .ticket-table-container > .ticket-table-row {
        /*        margin: 8px;*/
        border-bottom: 1px solid #E5E9EB;
        color: #252C32;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.176px;
    }

        .ticket-table-container > .ticket-table-row.selected {
            background-color: #E5E9EB;
        }

        .ticket-table-container > div > div {
            margin: 8px;
        }


.ticket-table-row > .client-unread {
    color: red;
    font-weight: 800;
    font-size: 0.9rem;
}