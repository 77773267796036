.menu-container {
    padding: 24px 12px;
    width: 270px;
    /* Black & White / White */
    background: #FFFFFF;
    /* Light Gray / Light Gray 4 */
    border-right: 1px solid #EEF0F2;
    box-shadow: 0px 5px 10px rgba(46, 61, 98, 0.05);
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

    .menu-container.collapsed {
        width: 70px;
    }

    .menu-header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 24px;
        border-bottom: 1px solid #EEF0F2;
        margin-bottom: 14px;
    }

.menu-top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.menu-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.menu-logo {
    margin: 0 12px;
    width: 40px;
    height: 48px;
}

.menu-logo.collapsed {
    margin: 0 0;
}

.menu-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */
    letter-spacing: -0.006em;
    text-transform: uppercase;
    /* Dark Gray / Dark Gray 3 */
    color: #303940;
}

.menu-description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: -0.011em;
    /* Mid Gray / Mid Gray 2 */
    color: #6E7C87;
}

.menu-container ul {
    list-style: none;
    font-size: 14px;
    margin: 0;
    padding: 0;
}

.menu-container .munu-link {
    text-decoration: none !important;
    gap: 8px;
    height: 40px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: -0.011em;
    color: #252C32;
}

.menu-item-link {
    text-decoration: none !important;
    margin: 2px 0;
    padding: 8px 12px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.menu-item {
/*    padding-left: 28px;*/
/*    position: relative;*/
}

.menu-left-icon {
    /*    display: flex;
    align-items: center;
    justify-content: center;*/
    width: 20px;
    /*    height: 20px;*/
/*    display: block;*/
/*    margin: 0 auto;*/
display:flex;
    margin-right: 10px;
    /*    color: #252C32;*/
}
.menu-top > .menu-left-icon > svg, .menu-top path {
    color: #252C32;
    stroke: #252C32;
}

.menu-item-name {
    width: calc(100% - 40px);
/*    display: flex;*/
}
.menu-icon-right {
    /*    display: flex;*/
    width: 20px;
    display: block;
    display: flex;
    /*    margin: 0 auto;*/
    /*    height: 20px;*/
}

.menu-item-active {
    color: #1AC19D !important;
    background: #E4FCFC !important;
}

.submenu-active {
    color: #1AC19D !important;
    stroke: #1AC19D !important;
/*    width: 20px;*/
}
.submenu-active, .submenu-active > div > span > svg, .submenu-active > div > span > svg > path {
    color: #1AC19D !important;
    stroke: #1AC19D !important;
    /*    width: 20px;*/
    /*    height: 20px;*/
}


.menu-item.menu-item-active > a, .menu-item.menu-item-active > a > div > span > svg, .menu-item.menu-item-active > a > div > span > svg > path {
    color: #1AC19D !important;
    stroke: #1AC19D !important;
    width: 20px;
    /*    height: 20px;*/
}

.menu-item:hover, .menu-item:active, .menu-item-active {
    /* Light Gray / Light Gray 5 */
    stroke: #1AC19D !important;
    background: #F6F8F9;
    border-radius: 8px;
}

/* This fires as soon as the element enters the DOM*/
.menu-item-enter {
    /*We give the list the initial dimension of the list button*/
    max-height: 0;
    opacity: 0;
    padding: 0px 0px 0px 0px;
}
/* This is where we can add the transition*/
.menu-item-enter-active {
    max-height: 40px;
    opacity: 1;
    padding: 0px 5px 0px 10px;
    transition: all 300ms;
}
/* This fires as soon as the this.state.showList is false */
.menu-item-exit {
    max-height: 40px;
    opacity: 1;
    padding: 0px 5px 0px 10px;
}
/* fires as element leaves the DOM*/
.menu-item-exit-active {
    max-height: 0;
    opacity: 0;
    padding: 0px 0px 0px 0px;
    transition: all 300ms;
}

