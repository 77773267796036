body {
    font-family: Inter;
/*    overflow: hidden;*/
}

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1AC19D;
    border-color: #1AC19D;
}
.btn-primary:hover {
    color: #fff;
    background-color: #138d72;
    border-color: #138d72;
}

.modal-dialog {
    max-width: 60% !important;
}

.icon-button {
    cursor: pointer;
    margin-left: 8px;
    margin-right: 8px;
}

.parent {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    min-height: 600px;
}

.block {
    width: 320px;
    height: 458px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.login-page-header-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.logo {
    height: 100px;
    width: 100px;
    margin-bottom: 16px;
}

.login-header-text {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    /*    letter-spacing: -0.006em;*/
    text-align: left;
    margin-bottom: 56px;
    color: #303940;
}

.login-text {
    color: #252C32;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    /*    letter-spacing: -0.011em;*/
    text-align: left;
}

.forgot-password {
    cursor: pointer;
    color: #0D9676;
    border-bottom: 2px solid rgba(13, 150, 118, 0.2);
    height: 24px;
}

.auth-button {
    margin: 12px 0;
}

.auth-input {
    /*    margin: 8px 0;*/
    margin-top: 4px;
    margin-bottom: 8px;
}

.login-additional {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
    color: #252C32;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.spinner-auth-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}


/*Блок админка банки*/
.banks-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    flex: 1;
}

.container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
.container-btn {
    display: flex;
    flex-direction: row;
}

.container-header-name {
    /*    font-size: 16px;*/
    font-weight: 600;
    padding: 0.375rem 0;
    font-size: 1rem;
}

.no-table-data {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    min-height: 400px;
}

.banks-table {
    /* border: 1px solid #2B2927; */
    border-radius: 6px;
    overflow: hidden;
    min-height: 400px;
    position: relative;
}

.bank-id {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 50px;
}

.bank-name {
    flex: 1;
}
.bank-email {
    flex: 1;
}
.bank-name-role {
    flex: 1;
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 100px;
}

.banks-header {
    display: flex;
    flex-direction: row;
    color: var(--Mid-Gray-Mid-Gray-2, #6E7C87);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.176px;
}
.banks-row>.bank-name-role {
    padding: 0px 8px 0px 0px;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    flex-wrap: nowrap;
}
.banks-row>.bank-email {
    padding: 0px 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
.bank-name-role::before {
    content: attr(data-role);
  }
  
  .bank-name-role[data-role="Администратор"] {
    /* background-color: #FFEFEB; */
    /* border-radius: 6px; */
    color: #CC0905; /* Красный цвет для администратора */
  }
  
  .bank-name-role[data-role="Менеджер"] {
    /* background-color: #EBF7FF; */
    /* border-radius: 6px; */
    color: #0452C8; /* Красный цвет для администратора */
  }

    .banks-header > .bank-id {
        padding: 8px;
    }
    .banks-header > .bank-name-role {
        padding: 12px 24px 8px 0px
    }

    .banks-header > .bank-name {
        padding: 12px 0px 8px 8px;
    }
    .banks-header > .bank-email {
        padding: 12px 0px 8px 0px;
    }

    /* .banks-header > .actions {
        padding: 8px;
    } */

.banks-row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #E1E1E1;
}
.banks-row.selected {
    background-color: #F0F4F6;
}

    .banks-row > .bank-id {
        padding: 8px;
    }

    .banks-row > .bank-name {
        padding: 8px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
    }
    .banks-row > .bank-email {
        padding: 0px 14px 0px 6px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
    }

    .banks-row > .actions {
        padding: 8px 0px 0px 24px;
    }

    .banks-row > .bank-name > .name {
        min-width: 30%;
        /*        flex-wrap: nowrap;*/
        color: var(--Dark-Gray-Dark-Gray-2, #252C32);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.176px;
    }

    .banks-row > .bank-name > div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

        .banks-row > .bank-name > div > .type {
            display: flex;
            flex-direction: row;
            align-items: center;
            /* padding: 0px 4px;
            margin: 2px 4px; */
            font-size: 14px;
            /* border: 1px solid rgba(236, 114, 54, 100%);
            background-color: rgba(236, 114, 54, 30%); */
            border-radius: 6px;
            border: 1px solid #E5E9EB;
            padding: 4px 4px;
            margin: 0px 4px;
        }

            .banks-row > .bank-name > div > .type > .cashback {
                /* background-color: #ede7e6; */
                /* margin: 4px 4px 4px 4px; */
                padding: 0 4px;
                border-radius: 4px;
                /*margin: 2px 0 2px 8px;
                padding: 0 4px;*/
                /* border: 1px solid red; */
                /*word-wrap: unset;*/
            }
            
              .cashback-no {
                /* Стили для значения кэшбэка */
                background-color: #E5E9EB;
                color: black;
                padding: 2px 8px;
                border-radius: 6px; 
              }
              .cashback-sigma {
                /* Стили для значения кэшбэка */
                background-color: #EBF7FF;
                color: #0452C8;
                padding: 2px 0px 2px 8px;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;  
              }
              .cashback-percentage {
                /* Стили для значения кэшбэка */
                background-color: #EBFFF1;
                color: #119C2B;
                padding: 2px 0px 2px 8px;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px; 
              }
              .cashback-value-1 {
                background-color: #EBF7FF;
                color: #0452C8;
                padding: 2px 8px 2px 0px;
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
              }
              .cashback-value-2 {
                background-color: #EBFFF1;
                color: #119C2B;
                padding: 2px 8px 2px 0px;
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;  
              }                  

    .banks-row > .bank-name > .type {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 4px;
        margin: 2px 4px;
        font-size: 14px;
        border: 1px solid rgba(236, 114, 54, 100%);
        background-color: rgba(236, 114, 54, 30%);
        border-radius: 6px;
    }

        .banks-row > .bank-name > .type > .cashback {
            background-color: #ede7e6;
            margin: 4px;
            padding: 4px;
            border: 1px solid red;
            border-radius: 4px;
            /*            word-wrap: unset;*/
        }

.insurance-id {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 50px;
}

.insurance-name {
    flex: 1;
}

.insurance-header {
    /* display: flex;
    flex-direction: row;
    background: #2b2927;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px; */
    text-align: left;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        /*        background: #2b2927;*/
        border-bottom: 1px solid #E5E9EB;
        color: #6E7C87;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.176px;
}

    .insurance-header > .insurance-id {
        padding: 8px;
    }

    .insurance-header > .insurance-name {
        padding: 8px;
    }

    .insurance-header > .actions {
        padding: 8px;
    }

.insurance-id {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 50px;
}
.name-insurances{
    display: flex;
    width: 180px;
    padding-top: 8px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 7px;
    align-self: stretch;
}

.insurance-row {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #E1E1E1;
}

    .insurance-row > .insurance-data > .insurance-id {
        padding: 8px;
    }

    .insurance-row > .insurance-data > .insurance-name {
        padding: 8px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
    }

    .insurance-row > .insurance-data > .actions {
        padding: 8px;
    }

.insurance-data {
    display: flex;
    flex-direction: row;
}

.insurance-additional-data {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.insurance-additional {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: 46%;
    /* border: 1px solid gray; */
    padding-bottom: 24px;
    border-radius: 6px;
}
.styled-button.primary.text-button {
    display: flex;
    border: 1px solid #EEF0F2;
    background-color: #EEF0F2;
    color: black;
    width: 100%;
    margin: 0px;
    justify-content: center;
    color: var(--Dark-Gray-Dark-Gray-2, #252C32);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.176px;
}

    .insurance-additional > .request-type-selector {
        width: 100%;
        border-bottom: none !important;
        padding: 0 !important;
        align-items: center;
    }

    .insurance-additional > .cashback-config {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 15px;
    }

        .insurance-additional > .cashback-config > .cashback-select {
            display: flex;
            flex-direction: column;
            gap: 4px;
            /* flex: 2; */
            /* align-items: center; */
        }

        .insurance-additional > .cashback-config > .cashback-data {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            flex: 1 0 0;
            align-self: stretch;
        }


select.cashBack-select {
    /* margin-left: 16px; */
    cursor: pointer;
    box-sizing: content-box;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    border-radius: 6px;
    border: 1px solid #DDE2E4;
    width: 150px;
    height: 24px;
}
.input-cashback-data {
    width: 176px;

}

.icon-action {
    margin: 0 4px;
    cursor: pointer;
}

.insurance-name {
    flex: 2;
}

.insurance-cashback {
    flex: 1;
}

.type-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    /* position: relative; */
    /* margin-top: 16px; */
    /*    margin: 16px;*/
    /*    padding: 0 16px;*/
    /* border: 2px solid rgba(236, 114, 54, 60%);
    border-radius: 6px; */
    /*    overflow: hidden;*/
}
.type-wrapper-bank {
    display: flex;
    flex-direction: column;
}
.bank-modal {
    display: flex;
    width: 30%;
}

.wrapper-header {
    color: var(--Dark-Gray-Dark-Gray-2, #252C32);
    /* Headings / Heading 18 Semi Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.252px;
    padding: 16px 0px;
}
.wrapper-header-bank {
    color: var(--Dark-Gray-Dark-Gray-2, #252C32);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.176px;
}
.name-bank {
    display: flex;
    padding-bottom: 4px;
}
.plus-add-name {
    display: flex;
    flex-direction: row;
    gap: 4px;
}
.request-type-selector {
    display: flex;
    justify-content: space-between;
    width: 50%;
    flex-wrap: wrap;
    padding: 16px;
    /*    border-bottom: 1px solid #E1E1E1;*/
    /*    border-right: 1px solid #E1E1E1;*/
}

    .request-type-selector:nth-child(even) {
        border-right: 1px solid #E1E1E1;
    }

    .request-type-selector:not(:last-child) {
        /* border-bottom: 1px solid #E1E1E1; */
        display: flex;
        align-items: center;
        padding: 4px;
    }
.request-type-selector-bank {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 0px;
    align-items: center;
}
/**/
.input-header {
    color: var(--Dark-Gray-Dark-Gray-2, #252C32);
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.176px;
    padding-bottom: 16px;
}
.name-insurances-company {
    padding-bottom: 4px;
}
.name-insurances {
    color: var(--Dark-Gray-Dark-Gray-2, #252C32);
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.176px;
    align-items: flex-start;
}


.lef-menu-wrapper {
    display: flex;
    flex-direction: row;
    min-height: Calc(100vh - 75px);
    flex-wrap: nowrap;
}

.left-menu {
    padding-right: 24px;
}


/* управление настройкам и франшизы */
.franchise-container {
    /* margin-top: 8px; */
    /* padding: 8px; */
    /* border: 1px solid black; */
    border-radius: 6px;
}
.big-container-franchise{
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--Dark-Gray-Dark-Gray-2, #252C32);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.176px;
    gap: 12px;
}

.franchise-size-input-container {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.franchise-size-input {
    max-width: 48%;
    /* margin: 8px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    padding-bottom: 12px;
}

.franchise-size {
}






.input-radio {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
}
    .input-radio > input {
        margin-top: 5px;
    }

.input-radio-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.input-radio-group-doc {
    display: flex;
    flex-direction: column;
    /* gap: 24px; */
}

.upload-label {
    display: none;
}
