.users-list-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid #2B2927;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid #E1E1E1;
}

    .users-list-container> .list-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #E1E1E1;
        padding-top: 8px;
        padding-bottom: 8px;
    }

        .users-list-container> .list-row.header {
            background-color: #2B2927;
            color: #ffffff;
            border-bottom: 1px solid #2B2927;
        }

        .users-list-container> .list-row > .center {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 16px;
            margin-right: 16px;
        }

        .users-list-container> .list-row > .flex-start {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-left: 16px;
            margin-right: 16px;
            flex-wrap: wrap;
        }

        .users-list-container> .list-row > .id {
            width: 5%;
        }

        .users-list-container> .list-row > .email {
            width: 20%;
        }

        .users-list-container > .list-row > .projects {
            width: 55%;
        }

        .users-list-container > .list-row > .actions {
            width: 10%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }


.project-element {
    background-color: rgba(236, 114, 54, 40%);
    border-radius: 4px;
    border: 1px solid rgb(236, 114, 54);
    overflow: hidden;
    margin: 0 8px 4px 0;
    padding: 0 8px;
/*    word-wrap: unset;*/
    /*    color: #ffffff;*/
}
.action {
    /* border: 1px solid red; */
    width: 70px;
    text-align: center;
}