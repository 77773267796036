
.distribution-users-header, .distribution-users-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.distribution-users-row {
    cursor: pointer;
    padding: 4px 0px 4px 0px;
}

.distribution-user-name {
    
}
.modal-header {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* background: #2b2927; */
    border-bottom: 1px solid #E5E9EB;
    color: #000;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.176px;
}
.distribution-users-header {
    padding: 8px 0px 8px 0px;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* background: #2b2927; */
    /* border: 1px solid #E5E9EB; */
    color: #6E7C87;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.176px;
}
.distribution-users-row {
    border-bottom: 1px solid rgb(237, 234, 234);
}
.distribution-users-row.selected {
    border:1px solid #8CEEE6;
    border-radius: 6px;
    padding: 4px 2px 4px 2px;
    background-color: #EBFFF1;
}