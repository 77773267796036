.client-info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
}
.client-info-left {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 8px 0px;
    gap: 16px;
}
.client-info-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #DDE2E4;
    border-radius: 12px;
    padding: 8px 16px;
}
.client-info-right {
    display: flex;
    justify-content: start;
    flex: 0.5;
    width: auto; 
    height: 289px; 
}

.client-info-img {
    /* max-width: 100%; 
    max-height: 100%; 
    min-width: 1%;
    min-height: 1%;
    width: 100%; 
    height: auto;  */
    height: 100%;
    width: auto;
    object-fit: cover;
    padding: 8px 0px;
}
.client-info-ticket {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 16px;
}
.client-info-buttons {
    display: flex;
    justify-content: flex-end;
     
}


/*    .chat-messages>div:last-child {
        position: sticky;
        bottom: 0;
    }*/
.chat-messages {
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    border-radius: 8px;
    padding: 6px 12px;
    gap: 12px;
    overflow-y: scroll;
    width: 100%;
    height: 59vh;
    }
.manager-message {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: lightgreen;
    margin-left: 30%;
    border-radius: 12px;
    padding: 6px 12px;
    width: fit-content;
    align-self: flex-end;
}

.client-message {
    display: flex;
    flex-direction: column;
    background-color: antiquewhite;
    margin-right: 30%;
    border-radius: 12px;
    padding: 6px 12px;
    width: fit-content;
    align-self: flex-start;
}
.send-button {
    width: 100px;
    height: 80px;
    border: 1px solid #1AC19D;
    fill: #1AC19D;
    border-radius: 12px;
    transition: ease-in-out;
    transition-duration: .2s;
    /*border-top: 70px solid #20a3bf;
    border-bottom: 70px solid transparent;*/
/*    border-right: 70px solid transparent;*/
}
    .send-button:hover {
        cursor: pointer;
        background-color: #1AC19D;
        fill: white;
    }
    .file-button {
        cursor: pointer;
        width: 100px;
        height: 30px;
        border: 1px solid gray;
        border-radius: 6px;
    }
    .file-button:hover {
        background-color: #ecf2f4;
    }
        .manager-send-message-container {
            display: flex;
            justify-content: center;
            gap: 24px;
            text-align: center;
            align-items: center;
            align-content: center;
            margin-top: 12px;
        }

.text-area-message {
/*    margin-top: 12px;*/
    border-radius: 6px;
    padding: 8px;
    width: 90%;
}

.message-container {
    display: flex;
    flex-direction: column;
}
.message-datetime {
    font-size: 12px;
    color: #000000; /* цвет даты/времени сообщения */
    background-color: transparent;
  }

.chat-img {
    max-width: 100%;
    cursor: pointer;
}

.chat-document {
    width: 80px;
    height: 80px;
    cursor: pointer;
}
.btn-send-message {
    display: flex;
    flex-direction: column;
    gap: 8px;
}