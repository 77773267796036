.modal-body{
    /* position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0; */
    display: flex;
    flex-direction: column;
    padding: 0px 32px 0px 32px;
    gap: 12px;
    
}
.modal-footer {
    display: flex;
    justify-content: space-between;
    border-top: 0px;
    padding: 24px 32px 32px 32px;
}
.modal-header {
    padding: 32px 32px 24px 32px;
    border-bottom: 0px;
}
.button-color-whyte {
    background-color: white;
    color: black;
    width: 46%;
    border: 1px solid #DDE2E4;
    margin: 0px;
    height: 45px;
    padding: 0px;
}
.button-color-whyte:hover {
    background-color: #DDE2E4;
    color: black;
    border: 1px solid #DDE2E4;
}
.styled-button.primary.button-save{
    width: 46%;
    margin: 0px;
    height: 45px;
    padding: 0px;
}
.row-button {
    padding: 12px 0px 0px 0px;
    width: 100%;
}
.form-control {
    outline: 0;
    box-shadow: 0;
}
.row-input-small {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.modal-window {
    /* border: 1px solid red; */
    width: 30%;
}
.button-generate{
    margin: 0px;
}