.ticket-page-selector {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid #EEF0F2;
    margin-bottom: 16px;
}

.ticket-page-selector>.sub-page-name {
    display: flex;
    padding: 12px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*        border-bottom: 2px solid #1AC19D;*/
    color: #6E7C87;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    cursor: pointer;
}

.ticket-page-selector>.sub-page-name.active {
    color: #252C32;
    border-bottom: 2px solid #1AC19D;
    margin-bottom: -2px;
}

.ticket-data-info-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.ticket-data-info {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.ticket-data-notes {
    display: flex;
    flex-direction: column;
    width: 40%;
    /*    border: 1px solid red;
    margin-left: 16px;
    padding: 8px;*/
    /*    color: #ffffff;*/
    margin-left: 24px;
    margin-bottom: 12px;
    /*    border-left: 2px solid #EEF0F2;*/
    border-top-left-radius: 6px;
}

.string-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    color: #000000;
    /*    padding-bottom: 8px;*/
    /*    margin-left: 24px;*/
    padding: 8px 16px;
    color: #252C32;
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    height: 44px;
    border-radius: 6px;
}

.string-container>.string-key {
    /*        width: 50%;*/
}

.string-container>.string-value {
    /*        width: 70%;*/
    width: 30%;
    min-width: 30%;
    max-width: 70%;
    padding: 0.375rem 0.75rem;
}

.string-value {
    /*        width: 70%;*/
    padding: 0.375rem 0.75rem;
}

.string-input-buttons {
    cursor: url(can-edit.cursor.svg), default;
}

.string-input-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: calc(2.25rem + 2px);
    padding: 10px;
    border-radius: 0px 6px 6px 0px;
    border: 1px solid #DDE2E4;
    background: #EEF0F2;
    cursor: default;
}

.string-input-buttons-container>div {
    height: 24px;
    border-right: 1px solid #DDE2E4;
    margin: 0 12px;
}

.string-input-buttons-container>svg {
    cursor: pointer;
}

/*.post a {
    cursor: url(images/cursor-1.png), url(images/cursor-2.cur), default;
}*/
div.string-input-buttons>.text-field {
    margin-bottom: 0px;
    /*  padding: 0.375rem 0.75rem*/
}

.string-input-buttons>.text-field>.text-field__input {
    /*    text-align: end;*/
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.group-container {
    /*    background-color: red;*/
    color: #ffffff;
    margin-left: 24px;
    margin-bottom: 12px;
    padding-left: 24px;
    border-left: 2px solid #EEF0F2;
    border-top-left-radius: 6px;
    /*    padding-left: 12px;*/
}

.object-container {
    /*    background-color: seagreen;*/
    color: #ffffff;
    margin-bottom: 12px;
    border-left: 2px solid #EEF0F2;
    border-top-left-radius: 6px;
    /*    margin-top: 12px;*/
    /*        padding-left: 12px;*/
}

.object-container>.string-container {
    margin-left: 24px;
}

.group-container>.string-container {
    margin-left: 24px;
}

.group-container:has(.string-container) {
    padding-left: 0px;
}

.object-name {
    /*    padding-left: -12px;*/
    border-radius: 6px;
    overflow: hidden;
    background: #EEF0F2;
    display: flex;
    padding: 12px 16px;
    margin-bottom: 16px;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    align-self: stretch;
    color: #252C32;
    /* Headings / Heading 18 Semi Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: -0.252px;
}

.object-name>svg {
    cursor: pointer;
}

.string-input-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.string-input-buttons>svg {
    /*        padding: 4px;*/
    cursor: pointer;
}

/*Commercial offer sub page*/
/**/
/**/


/*blok ticket add commercial offer*/


.insurance-offer {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    gap: 16px;
    margin-top: 40px;
}

.insurance-offer-ticket {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    gap: 16px;
    background-color: white;
}

.company-offers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    /* padding-left: 16px; */
    /* padding-bottom: 16px; */
    /* margin: 0px 16px; */
    /* margin-left: -8px;
    margin-right: -8px; */
}

.company-offers-kasko {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
}

/* .insurance-offer > .company-name {
    color: white;
    border-radius: 6px;
    background: #EEF0F2;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 12px 16px;
    align-items: center;
    color: #252C32;
    /* Headings / Heading 18 Semi Bold */
/* font-family: Inter; */
/* font-size: 18px; */
/* font-style: normal; */
/* font-weight: 600; */
/* line-height: 24px; 133.333% */
/* letter-spacing: -0.252px; */
/*    gap: 16px;*/


.company-offers.small {
    color: white;
    border-radius: 6px;
    /*    background: #EEF0F2;*/
    flex-direction: row;
    /*    justify-content: space-between;
    align-items: center;*/
    display: flex;
    /* padding: 0px 8px; */
    /*    align-items: center;*/
    color: #252C32;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.252px;
    flex-wrap: wrap;
    gap: 29px;
}

.company-offers.small>.company-name {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background: #EEF0F2;
    padding: 12px;
    gap: 8px;
    min-width: 23%;
}

.company-name-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 16px 4px 24px;
    font-weight: 600;
}

.company-name-row-add {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #EEF0F2;
    border-radius: 6px;
    padding: 12px 16px;
}

.company-name.small {
    /*    width: 100%;*/
}

.company-name-row-osago {
    display: flex;
    justify-content: space-between;
    min-width: 25%;
}

.company-name-summ {
    /* padding: 0px 16px 0px 0px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    /* padding-right: 24px; */
    /* font-size: 18px; */
    /* padding-right: 16px; */
}


.company-offers.small>.company-name>.text-field,
.company-name-summ>.text-field {
    margin-bottom: 0;
}

.company-name.middle {
    color: white;
    border-radius: 6px;
    /*    background: #EEF0F2;*/
    flex-direction: row;
    /*    justify-content: space-between;
    align-items: center;*/
    display: flex;
    padding: 0px !important;
    /*    align-items: center;*/
    color: #252C32;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.252px;
    /*    flex-wrap: wrap;*/
    gap: 12px;
    width: 100%;
    background: #FFFFFF !important;
    overflow: hidden;
}


.company-name.middle>.ifl-offer {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
}

.insurance-selector-name {
    margin-bottom: 16px;
}

.ifl-offer {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.ifl-offer>.offer-item {
    margin: 0px;
}

.ifl-offer-ifl>.offer-item {
    margin: 0px;
}

/*.offer-row {*/
/*    width: 200px;*/
/*display: flex;
    flex-direction: row;
}*/
.offer-item {
    display: flex;
    flex-direction: column;
    padding: 16px;
    /* margin: 8px; */
    width: calc(25% - 12px);
    border-radius: 8px;
    border: 1px solid #DDE2E4;
    /* min-height: 200px; */
    background-color: white;
    height: fit-content;
}

.offer-item-kasko {
    display: flex;
    flex-direction: column;
    padding: 12px;
    /* margin: 8px; */
    width: calc(25% - 16px);
    border-radius: 8px;
    border: 1px solid #DDE2E4;
    /* min-height: 200px; */
    background-color: white;
    height: fit-content;
    position: relative;
}

.add-offer {
    width: calc(25% - 12px);
    display: flex;
    padding: 8px;
    /* margin: 8px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    cursor: pointer;
    border-radius: 8px;
    border: 1px dashed #DDE2E4;
    background: #F6F8F9;
    color: #252C32;
    text-align: center;
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    min-height: 200px;
}

.add-offer>svg {
    stroke: #6E7C87
}

.add-commercial-offer {
    /*    width: calc(25% - 16px);*/
    display: flex;
    padding: 8px;
    margin-top: 8px;
    margin-bottom: 0px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    cursor: pointer;
    border-radius: 8px;
    border: 1px dashed #B0BABF;
    background: #F6F8F9;
    color: #B0BABF;
    text-align: center;
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    min-height: 100px;
}

.add-commercial-offer>svg {
    stroke: #B0BABF
}

.full-kasko-header {
    display: flex;
    padding: 10px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 6px;
    background: #F8C51B;
    margin-bottom: 12px;
}

.franchizes-container {
    /*    width: 100%;*/
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.offer-item>.styled-button {
    width: 100%;
    margin: 0px;
    margin-top: 12px;
}

.offer-item>.styled-button>div {
    padding-left: 16px;
}

.check-offer {
    display: flex;
    padding: 4px 0px;
    align-items: flex-start;
    gap: 8px;
    align-items: center;
}

.franchize-offers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.offer-summ {
    padding: 4px 0px;
}

.summ-franschize {
    font-weight: 600;
}

.name-summ-franschize {
    display: flex;
    justify-content: space-between;
    background-color: #F6F8F9;
    padding: 8px;
    border-radius: 6px;
    margin-bottom: 8px;
}

.offer-last-check-box {
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #DDE2E4;
    width: 100%;
}
.offer-last-check-box-ifl {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #DDE2E4;
    width: 100%;
}

/*.delete-offer-icon {
    right: 8px;
    top: 8px;
    cursor: pointer;
}
*/
.franschize-size-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    background-color: #fff;
    /*    border-bottom: 1px solid gray;*/
}

.franchize-sizes-selector>.button-selector {
    padding-bottom: 4px;
}

.franschize-size-row-summ {
    width: 40%;
}

.franschize-size-row>.text-field {
    margin-bottom: 0px;
    width: 50%;
}

.franschize-size-row.no-franchise>.text-field {
    margin-bottom: 0px;
    width: 100%;
}

.item-summ {}

.kp-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 24px;
}

.kp-buttons-container>.kp-button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.ticket-data-summary-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #252C32;
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    height: 44px;
}

.ticket-data-summary-row-header {
    color: var(--dark-gray-dark-gray-2, #252C32);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    height: 34px;
    margin-top: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ticket-note {
    width: 100%;
    height: auto;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 6px;
    background: rgba(255, 205, 5, 0.25);
    /*    min-height: 100px;*/
    box-sizing: border-box;
    margin-bottom: 16px;
}

.ticket-note-data {
    /*    height: 100%;*/
    /*    min-height: 60px;*/
    /*    opacity: 0.25;*/
}

.ticket-note-line {
    width: 100%;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.25);
}

.ticket-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
}

.ticket-bottom-data {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #000;
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    opacity: 0.5;
}

.ticket-bottom-buttons {
    display: flex;
    flex-direction: row;
}

.ticket-bottom-buttons>div {
    height: 24px;
    border-right: 1px solid rgba(0, 0, 0, 0.25);
    margin: 0 12px;
}

.ticket-bottom-buttons>svg {
    cursor: pointer;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: black;
    margin: 0 8px;
}

.saved-offer-header {
    display: flex;
    flex-direction: row;
    padding: 16px 16px;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 6px;
    /* border: 1px solid var(--light-gray-light-gray-4, #EEF0F2); */
    /* margin-bottom: 24px; */
}

.saved-offer-results {
    width: calc(50% - 8px);
    border: 1px solid var(--light-gray-light-gray-2, #DDE2E4);
    border-radius: 6px;
    background-color: white;
    padding: 16px 24px;
    max-height: fit-content;
}

.saved-offer-comments {
    width: calc(50% - 8px);
    border: 1px solid var(--light-gray-light-gray-2, #DDE2E4);
    border-radius: 6px;
    background-color: white;
    padding: 16px 24px;
    height: 106px;
    /* max-height: fit-content; */
}

.group-button {
    gap: 12px; 
    display: flex;
    flex-direction: column;
    padding: 16px 0px 0px 0px;
}

._button {
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;
    cursor: pointer;
    background-color: var(--teal-t-600, #1AC19D);
    color: #ffffff;
    border-radius: 6px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.096px;
    position: relative;
    /* transition: background-color 3000ms; */
}

._button.secondary {
    color: var(--dark-gray-dark-gray-2, #252C32);
    background-color: var(--light-gray-light-gray-4, #EEF0F2);
    /* transition: background-color 3000ms; */
}

._button.primary {
    background-color: var(--teal-t-600, #1AC19D);
    color: #ffffff;
    /* transition: background-color 3000ms; */
}
._button.primarytgb {
    background-color: var(--teal-t-600, #50A7EA);
    color: #ffffff;
    width: 100%;
    /* transition: background-color 3000ms; */
}
._button.primarytgw {
    background-color: var(--teal-t-600, #fff);
    color: #000;
    border: 1px solid #DDE2E4;
    /* transition: background-color 3000ms; */
}
._button.primarytgg {
    background-color: white;
    color: #000;
    border: 1px solid #DDE2E4;
    /* transition: background-color 3000ms; */
}
._button.primarytgg {
    background-color: white;
    color: #000;
    border: 1px solid #DDE2E4;
    /* transition: background-color 3000ms; */
}
._button.primarytga {
    background-color: #DDE2E4;
    color: #000;
    border: 1px solid #DDE2E4;
    /* transition: background-color 3000ms; */
}

._button.danger {
    background: #FFEFEB;
    color: #F2271C;
    /* transition: background-color 3000ms; */
}

/* ._button.busy {
    cursor: not-allowed;
} */

._button.disabled {
    cursor: not-allowed;
    background-color: #707171;
    color: #f7f9fb;
    /* transition: background-color 3000ms; */
}
._button.disabledtgb {
    cursor: not-allowed;
    background-color: #707171;
    color: #f7f9fb;
}
._button.disabledtgw {
    cursor: not-allowed;
    background-color: #707171;
    color: #f7f9fb;
}

._button-busy {
    cursor: not-allowed;
    color: red;
    position: absolute;
    top: 4px;
    right: 4px;
    border-radius: 4px;
    width: 8px;
    height: 8px;
    background-color: red;
    /* transition: background-color 3000ms; */
    /*    height: 100%;*/
}

.group-button-step {
    margin-top: 16px;
    display: flex; 
    flex-direction: column;
    gap: 12px;
    padding: 16px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: white;
}

.group-button-step-first {
    display: flex;
    flex-direction: column;
    gap:12px;
}
.group-button-step-second {
    display: flex;
    flex-direction: row;
}
.buttons-cells-primary {
    width: 100%;
}
.buttons-cells-secundary {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* justify-content: space-between; */
    gap: 12px;
}
.button-call-me {
    width: 100%;
}
.button-cells-more {
    display: flex;
    flex-direction: column;
}
.tree-dot-tg {
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    bottom: 9px;
    color: #000;
    font-size: 30px;
}
.text-button-inside {
    display: flex;
}
.icon-inside-button {
    position: absolute;
    right: 16px;
}
.open-cells-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
}
.buttons-cells {
    width: 50%;
}

.offerItemOsago {
    display: block;
    background-color: #F6F8F9;
    margin-top: 40px;
    border-radius: 12px;
}

.insurance-offer-osago-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: wrap;
    margin-left: 16px;
    margin-right: 16px;
    background-color: #F6F8F9;
    padding-bottom: 16px;
    justify-content: flex-start;
    flex-wrap: wrap;
    /*width: 30%;
    padding: 4px 0px;
    border-radius: 6px;
    margin: 8px 0px;*/
}
.responsibleSelected {
    /* border: 1px solid red; */
    /* width: 200px; */
    min-width: 200px;
}
.offer-item-osago-group {
    display: flex;
    border: 1px solid #DDE2E4;
    border-radius: 8px;
    justify-content: space-between;
    /* max-width: calc(24% - 6px); */
    padding: 8px;
    text-align: left;
    background-color: #fff;
    /* gap: 8px; */
    font-weight: 600;
    min-width: 30%;
    position: relative;
}

.offer-item-osago-name {}

.offer-item-osago-summ {
    display: flex;
    padding-left: 110px;
}

.company-name.middle-ifl {
    color: white;
    border-radius: 12px;
    /*    background: #EEF0F2;*/
    flex-direction: column;
    /*    justify-content: space-between;
    align-items: center;*/
    display: flex;
    padding: 0px !important;
    /*    align-items: center;*/
    color: #252C32;
    /* font-family: Inter;
    font-style: normal;
    line-height: 24px;
    letter-spacing: -0.252px; */
    /*    flex-wrap: wrap;*/
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    background-color: #F6F8F9;
    /* overflow: hidden; */
    margin-top: 40px;
    position: relative;
}

.company-name.middle-ifl-ticket {
    color: white;
    border-radius: 12px;
    /*    background: #EEF0F2;*/
    flex-direction: column;
    /*    justify-content: space-between;
    align-items: center;*/
    display: flex;
    padding: 16px !important;
    /*    align-items: center;*/
    color: #252C32;
    /* font-family: Inter;
    font-style: normal;
    line-height: 24px;
    letter-spacing: -0.252px; */
    /*    flex-wrap: wrap;*/
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    /* background-color: #F6F8F9; */
    overflow: hidden;
    /* margin-top:40px; */
    gap: 16px;
    position: relative;
}

.ifl-offer-ifl {
    display: flex;
    flex-direction: row;
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    gap: 16px;
    padding: 0px 0px 16px 16px;
}

.item-group-ifl {
    display: flex;
    justify-content: space-between;
    padding: 8px 0px;
    align-content: center;
}

.comapany-name-header {
    display: flex;
    flex-direction: column;
    background-color: #F6F8F9;
    border-radius: 12px;
}

.company-name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0px 4px 24px;
    font-weight: 600;
}

.company-name-kasko {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 16px;
    font-weight: 600;
    background-color: #EEF0F2;
    border-radius: 6px;
    font-size: 18px;
}

.company-name-ticket {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    padding: 8px 24px;
    background-color: #EEF0F2;
    border-radius: 6px;
    margin-top: 16px;
    font-size: 18px;
    font-weight: 600;
    justify-content: space-between;
}

.icons-delete-chevron-up {
    display: flex;
    padding: 0px 16px 0px 0px;
    gap: 16px;
}

.saved-offer-item-container {
    display: flex;
    flex-direction: column;
}
.note-textarea {
    width: 100%;
    height: fit-content;
    background-color: rgb(253, 243, 205);
    outline:none;
    border: none;
    resize: none;
    display: inline-table
}
.edited-note-text {
    
}

.offer-item-kasko.selected {
    border-radius: 8px;
    border: 4px solid var(--Teal-T600, #1AC19D);
    background: var(--black-white-white, #FFF);
}

.declined {
    border-radius: 8px;
    border:4px solid #F2271C !important;
    background-color: #FFF;
}

.offer-item-osago-group.selected {
    border-radius: 8px;
    border: 4px solid var(--Teal-T600, #1AC19D);
    background: var(--black-white-white, #FFF);
}

.middle-ifl.selected {
    border-radius: 8px;
    border: 4px solid var(--Teal-T600, #1AC19D);
    background: var(--black-white-white, #FFF);
}

.upload-file {
    display: flex;
    flex-direction: column;
    /* border: 1px solid gray; */
    border-radius: 8px;
}

.upload-file-row {
    display: flex;
    flex-direction: row;
    align-items: self-start;
}

    .upload-file-row>.custom-checkbox-container {
        margin-top: 0px;
    }

.files_buttons_container {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.uploaded-files-container {
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
    border: 1px solid #EEF0F2;
    margin: 12px 0px;
}
.line-horizontal {
    display: flex;
    border: 1px solid gray;
    padding: 4px;
}

.file-description {
    color: black;
    font-weight: 600;
    cursor: pointer;
    width: fit-content;
    padding: 10px 16px;
    border-radius: 6px;
    background-color: #EEF0F2;
    text-decoration: none;
}
.file-description:hover {
    color: black;
    font-weight: 600;
    cursor: pointer;
    width: fit-content;
    padding: 10px 16px;
    border-radius: 6px;
    background-color: #D7DBDE;
    text-decoration: none;
}
.name_end {
    display: flex;
}
.container-step-three {
    display: flex;
    flex-direction:column;
    gap: 16px;
}
.container-comentary {
    padding: 16px;
}
.comentary-text-area {
    border: 1px solid var(--light-gray-light-gray-2, #cdd3d5);
    padding: 6px;
    border-radius: 6px;
}
.text-container {
    color: var(--Mid-Gray-Mid-Gray-2, #6E7C87);
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.176px;
}
.input-radio-legend {
    color: var(--black-white-black, #000);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.252px;
}
.input-radio-legend-doc {
    color: var(--black-white-black, #000);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.252px;
    margin-bottom: 24px;
}
.delivery-container {
    display: flex;
    flex: row;
    gap: 16px;
}
.delivery-adres {
    display: flex;
    flex-direction: column;
}
.text-delivery {
    color: var(--black-white-black, #000);
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.176px;
    padding-bottom: 4px;
}
.delivery-date-time {
    display: flex;
    flex-direction: column;
}
.data-picker {
    width: 240px;
    height: 46px;
    border-radius: 6px;
    border: 1px solid var(--Light-Gray-Light-Gray-2, #DDE2E4);
    background: var(--black-white-white, #FFF);
    padding: 10px 16px;
    outline: none;
}
.upload-box {
    display: flex;
    flex-direction: row;
    /* position: relative;  */
    width: 100%;
    gap:8px;
    /* background-color: lightgray; */
    /* padding: 8px; */
}
.color-asterix {
    color: red;
}
.icon-upload > div > svg >path {
    stroke: #1AC19D;
}
.container-data-primary {
    background-color: gray;
}
.container-data {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: white;
    padding: 24px;
}
.input-container-delivery {
    border-radius: 6px;
    border: 1px solid var(--Light-Gray-Light-Gray-2, #DDE2E4);
    background: var(--black-white-white, #FFF);
    width: 528px;
    padding: 10px 16px;
    box-shadow: none;
    outline: none;
}
.icon-container-offer {
   position: absolute;
   top: -18px;
   right: -19px;
   border-radius: 100%;
   background-color: #1AC19D;
}
.red-icon-container{
    position: absolute;
    top: -18px;
    right: -19px;
    border-radius: 100%;
    background-color: #F2271C;
 }
 .franschize-size-row.selected {
    background-color: #E4FCFC;
    border-radius: 6px;
    padding: 6px 12px;
    border: 1px solid #1ac19d;
    /* border: 4px solid #1ac19d; */
    /* border-radius: 8px;
    padding: 4px; */
 }
 .franschize-size-row.declined {
    border-radius: 8px;
    border: 4px solid #F2271C;
    background:#FFEFEB;
 }

.previus-polis-files {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
}

.previus-polis-files-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
}

.previus-polis-files-button {
    border: 1px solid gray;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
}

    .previus-polis-files-button.download {
        background-color: gray;
        border-color: gray;
        color: white;
    }

        .previus-polis-files-button.download:hover {
            opacity: 0.9;
        }

    .previus-polis-files-button.create {
        background-color: #1AC19D;
        border-color: #1AC19D;
        color: white;
    }
        .previus-polis-files-button.create:hover {
            opacity: 0.9;
        }