.icon-messages-container {
    position: relative;
}

/*.icon-messages-count {
    position: absolute;
    font-size: small;
    color: red;
}*/

.icon-messages-count {
    position: absolute;
    font-size: 10px;
    color: white;
/*    border: 1px solid red;*/
/*    border-radius: 12px;*/
/*    background-color: white;*/
/*background-color: transparent;*/
    line-height: 10px;
    padding: 2px;
    font-weight:bold;
    left: 0px;
    bottom: 5px;
}

.icon-messages-count-red {
    color: red;
    font-weight: bold;
    font-size: 0.9rem;
}