.custom-select-container {
    display: inline-block;
    min-width: 60px;
    text-align: center;
    position: relative;
    cursor: pointer;
}

    .custom-select-container .selected-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: black;
        line-height: 24px;
        font-size: 16px;
        height: 44px;

        padding: 6px 30px 6px 6px;
        border-radius: 6px;
        border: 1px solid #DDE2E4;
        background: #FFF;
        /*    border-bottom: 1px solid #2D2E2E;*/
    }

    .custom-select-container:hover .selected-text:hover {
        border-color: #8CEEE6;
    }

        .custom-select-container .selected-text::after {
            content: "";
            position: absolute;
            right: 10px;
            top: 18px;
            border: 4px solid transparent;
            border-color: #8CEEE6 transparent transparent transparent;
        }

        .custom-select-container .selected-text.active::after {
            top: 16px;
            border-color: transparent transparent #8CEEE6 transparent;
        }

    .custom-select-container ul {
        margin: 0;
        padding: 0;
        text-align: center;
        border-radius: 6px;
        border: 1px solid transparent;
        overflow: hidden;
        z-index: 100;
    }

.top {
    top: auto;
    bottom: 44px;
}
.custom-select-container .select-options {
    position: absolute;
    width: 100%;

    border-radius: 8px;
    background: #FFF;
    /* Shadow / Elevation 4 */
    box-shadow: 0px 12px 24px 0px rgba(91, 104, 113, 0.24), 0px 0px 1px 0px rgba(26, 32, 36, 0.32);
}

.custom-select-container li {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    list-style-type: none;
    padding: 6px 6px;
    line-height: 24px;
    font-size: 16px;
    background: #FFFFFF;
    /*    border-bottom: 1px solid #37b0b0;*/
    height: 44px;
    color: #000000;
    cursor: pointer;
}

    .custom-select-container li:hover {
        background-color: #F6F8F9;
        color: #000000;
    }
