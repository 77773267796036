.ticket-table-container {
    display: flex;
    flex-direction: column;
    /*    border: 1px solid gray;*/
    border-radius: 6px;
    position: relative;
}

    .ticket-table-container > div {
        display: flex;
        flex-direction: row;
/*        border-bottom: 1px solid #E1E1E1;*/
    }

    .ticket-table-container > div {
        text-align: left;
    }

        .ticket-table-container > div > .id {
            width: 60px;
        }

        .ticket-table-container > div > .type {
            width: 100px;
        }

        .ticket-table-container > div > .date-created {
            width: 120px;
        }

        .ticket-table-container > div > .description {
            flex: 1;
/*            text-align: left !important;*/
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .ticket-table-container > div > .description:hover {
            white-space: normal;
            background-color: inherit; /* Прозрачный фон */
             /* Отступы вокруг текста при наведении */
            
        }

        .ticket-table-container > div > .status {
            width: 234px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            
        }
        .status-new {
            color: #119C2B;
            background-color: #EBFFF1;
            padding: 0px 8px;
            border-radius: 6px;
        }
        .status-colect-data {
            color: black;
            background-color: #E5E9EB;
            padding: 0px 8px;
            border-radius: 6px;
        }
        .status-waiting-kp {
            color: #835101;
            background-color: #FFFCC2;
            padding: 0px 8px;
            border-radius: 6px;
        }

        .ticket-table-container > div > .user {
            width: 160px;
        }

    .ticket-table-container > .ticket-table-header {
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        /*        background: #2b2927;*/
        border-bottom: 1px solid #E5E9EB;
        color: #6E7C87;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.176px;
    }

        .ticket-table-container > .ticket-table-header > div {
            /*            margin: 8px;*/
        }

        .ticket-table-container > .ticket-table-header > .description {
/*            text-align: left !important;*/
        }

    .ticket-table-container > .ticket-table-row {
        /*        margin: 8px;*/
        border-bottom: 1px solid #E5E9EB;
        color: #252C32;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.176px;
    }

        .ticket-table-container > .ticket-table-row.selected {
            background-color: #E5E9EB;
        }

        .ticket-table-container > div > div {
            margin: 8px;
        }

.styled-button.primary.btn-red {
    border: 1px solid #F2271C;
    background-color: white;
    color: #F2271C;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
}
.styled-button.primary.btn-red:hover {
    border: 1px solid #F2271C;
    background-color: rgb(247, 238, 238);
    color: #F2271C;
}
.icon-text {
    display: flex;
    gap: 8px;
}

.accent-text {
    font-weight: bold;
    color: red;
}