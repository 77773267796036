.layout {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    box-sizing: border-box;
    /*    height: height;*/
    /*    max-height: height;*/
}

.page-container {
    padding: 24px;
    width: calc(100% - 270px);
    box-sizing: border-box;
    overflow: auto;
}

    .page-container.collapsed {
        width: calc(100% - 70px);
    }