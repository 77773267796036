.header-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
}

.header-first {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.456px;
    padding-bottom: 12px;
}

.left-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 44px;
}

.page-name {
    margin-right: 16px;
}

.header-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.header-buttons:last-child {
    margin: 0;
}

.filterButton {
    position: relative;
    /*    border: 1px solid black;
    background-color: gray;*/
    display: flex;
    width: 44px;
    height: 44px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 6px;
    background: #EEF0F2;
}
    .filterButton > svg {
        fill: #252C32;
    }

    .filterButton.expanded {
        background: #1AC19D;
    }

        .filterButton.expanded > svg {
            fill: white;
        }

.filter-is-set {
    position: absolute;
    right: -5px;
    top: -5px;
    width: 14px;
    height: 14px;
    border: 2px solid white;
    border-radius: 7px;
    overflow: hidden;
    background-color: red;
    stroke-width: 2;
}