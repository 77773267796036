
.privacy_container {
    font-family: Inter;
    font-size: 10px;
    line-height: 1.6;
    margin: 20px;
    padding: 0;
    color: #333;
/*    position: relative;*/
/*    padding-bottom: 16px;*/
}

    .privacy_container:last-child {
        padding-bottom: 48px;
    }

.policy_header {
    padding-bottom: 16px;
    color: #50A7EA;
    font-weight: 600;
    font-family: Inter;
    font-size: 14px;
}

h1,
h2 {
    color: #2c3e50;
    font-size: 10px;
    font-family: Inter;
}

h1 {
    margin-top: 0;
}

ul {
    margin: 0;
    padding-left: 20px;
}

p {
    margin: 0 0 10px;
}

.section {
    margin-bottom: 20px;
}

    /*
.button-accept-container {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}*/
    .accept-button {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: calc(100% - 32px) !important;
        margin: 16px;
        /*z-index: 100;*/
        /*    float: left;*/
    }
