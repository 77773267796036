.button-selector-name {
    color: #252C32;
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.176px;
    padding-bottom: 16px;
}

.button-selector {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: #252C32;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.176px;
    margin-left: -4px;
    margin-right: -4px;
    padding-bottom: 24px;
}

.button-item {
    /*    padding: 10px 32px;*/
    padding: 10px 8px;
    border: 1px solid #EEF0F2;
    border-radius: 6px;
    background: #EEF0F2;
    cursor: pointer;
    margin: 4px;
}

    .button-item.active {
        border: 1px solid #1AC19D;
        background-color: #E4FCFC;
        color: #1AC19D;
    }
