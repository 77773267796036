.partners-table-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid #E5E9EB;
    color: #252C32;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.176px;
    cursor:pointer;
}
    .partners-table-row.selected {
        background-color: #E5E9EB;
    }

    .partners-table-row.header {
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        /*        background: #2b2927;*/
        border-bottom: 1px solid #E5E9EB;
        color: #6E7C87;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.176px;
    }

    .partners-table-row > .id {
        width: 5%;
        margin: 8px;
    }
    .partners-table-row > .fio {
        width: 35%;
        margin: 8px;
    }

    .partners-table-row > .phone {
        width: 30%;
        margin: 8px;
    }

    .partners-table-row > .email {
        width: 30%;
        margin: 8px;
    }