.custom-checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    position: relative;
}
.custom-checkbox-containertg-round {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-top: 4px;
    margin-bottom: 4px;
    padding-top: 4px;
    padding-bottom: 4px; */
}

.custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

    .custom-checkbox + label {
        display: inline-flex;
        align-items: center;
/*        user-select: none;*/
        cursor: pointer;
    }

        /*static*/
        .custom-checkbox + label::before {
            content: '';
            display: inline-block;
            width: 22px;
            height: 22px;
            flex-shrink: 0;
            flex-grow: 0;
            border-radius: 4px;
            border: 1px solid #B0BABF;
            background: #F6F8F9;
            border-radius: 0.25em;
            margin-right: 0.5em;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
        }

    /*checked*/
    .custom-checkbox:checked + label::before {
        border-radius: 4px;
        background: #1AC19D;
        border-color: #1AC19D;
        background-image: url("assets/ckeckbox.svg");
    }



    /* стили при наведении курсора на checkbox */
    .custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
        border-radius: 4px;
        border: 1px solid #9AA6AC;
        background: #EEF0F2;
    }

    /* стили при наведении курсора на checkbox + checked */
    .custom-checkbox:not(:disabled):checked + label:hover::before {
        border-radius: 4px;
        background-color: #0D9676;
        border-color: #0D9676;
        width: 22px;
        height: 22px;
    }

    /* стили для активного состояния чекбокса (при нажатии на него) + no_checked */
    .custom-checkbox:not(:disabled):active + label:hover:before {
        border-radius: 4px;
        border: 1px solid #84919A;
        background-color: #E5E9EB;
        width: 22px;
        height: 22px;
    }

    /* active + checked*/
    .custom-checkbox:not(:disabled):checked:active + label:hover:before {
        background-color: #0D9676;
        border-radius: 4px;
        width: 22px;
        height: 22px;
    }

    /*стили для чекбокса, находящегося в фокусе 
.custom-checkbox:focus+label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}*/

    /* стили для чекбокса, disabled и не находящегося в состоянии checked */
    .custom-checkbox:disabled:not(:checked) + label::before {
        border-radius: 4px;
        border: 1px solid #B0BABF;
        opacity: 0.5;
        background-color: #F6F8F9;
        width: 22px;
        height: 22px;
        border-width: 1.5px;
/*        color: red;*/
    }

    .custom-checkbox:disabled + label {
        color: #252C32;
        opacity: 0.5;
    }

    /* стили для чекбокса, находящегося в состоянии disabled + checked */
    .custom-checkbox:disabled + label::before {
        background-color: #D5DADD;
        border-color: #D5DADD;
        width: 22px;
        height: 22px;
        /*        color:red;*/
    }







    /*/////////////////////////////////////////////////////////////////////////*/

.custom-checkbox.tg {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

    .custom-checkbox.tg + label {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
    }

        /*static*/
        .custom-checkbox.tg + label::before {
            content: '';
            display: inline-block;
            width: 22px;
            height: 22px;
            flex-shrink: 0;
            flex-grow: 0;
            border-radius: 4px;
            border: 1px solid #B0BABF;
            background: #F6F8F9;
            border-radius: 0.25em;
            margin-right: 0.5em;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
        }

    /*checked*/
    .custom-checkbox.tg:checked + label::before {
        border-radius: 4px;
        background: #50A7EA;
        border-color: #50A7EA;
        background-image: url("assets/ckeckbox.svg");
    }



    /* стили при наведении курсора на checkbox */
    .custom-checkbox.tg:not(:disabled):not(:checked) + label:hover::before {
        border-radius: 4px;
        border: 1px solid #9AA6AC;
        background: #EEF0F2;
    }

    /* стили при наведении курсора на checkbox + checked */
    .custom-checkbox.tg:not(:disabled):checked + label:hover::before {
        border-radius: 4px;
        background-color: #50A7EA;
        border-color: #50A7EA;
        width: 22px;
        height: 22px;
    }

    /* стили для активного состояния чекбокса (при нажатии на него) + no_checked */
    .custom-checkbox.tg:not(:disabled):active + label:hover:before {
        border-radius: 4px;
        border: 1px solid #84919A;
        width: 22px;
        height: 22px;
    }

    /* active + checked*/
    .custom-checkbox.tg:not(:disabled):checked:active + label:hover:before {
        background-color: #50A7EA;
        border-radius: 4px;
        width: 22px;
        height: 22px;
    }
        /*/////////////////////////////////////////////////////////////////////////*/
        .custom-checkbox.tg-round {
            position: absolute;
            z-index: -1;
            opacity: 0;
            border-radius: 100%;
        }
        
            .custom-checkbox.tg-round + label {
                display: inline-flex;
                align-items: center;
                cursor: pointer;
                border-radius: 100%;
            }
        
                /*static*/
                .custom-checkbox.tg-round + label::before {
                    content: '';
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                    flex-shrink: 0;
                    flex-grow: 0;
                    border-radius: 4px;
                    border: 1px solid #DDE2E4;
                    background: #F6F8F9;
                    border-radius: 0.25em;
                    /* margin-right: 0.5em; */
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 50% 50%;
                    border-radius: 100%;
                }
        
            /*checked*/
            .custom-checkbox.tg-round:checked + label::before {
                border-radius: 4px;
                background: #50A7EA;
                border-color: #50A7EA;
                background-image: url("assets/ckeckbox.svg");
                border-radius: 100%;

            }
        
        
        
            /* стили при наведении курсора на checkbox */
            .custom-checkbox.tg-round:not(:disabled):not(:checked) + label:hover::before {
                border-radius: 4px;
                border: 1px solid #9AA6AC;
                background: #EEF0F2;
                border-radius: 100%;
            }
        
            /* стили при наведении курсора на checkbox + checked */
            .custom-checkbox.tg-round:not(:disabled):checked + label:hover::before {
                border-radius: 4px;
                background-color: #50A7EA;
                border-color: #50A7EA;
                width: 22px;
                height: 22px;
                border-radius: 100%;
            }
        
            /* стили для активного состояния чекбокса (при нажатии на него) + no_checked */
            .custom-checkbox.tg-round:not(:disabled):active + label:hover:before {
                border-radius: 4px;
                border: 1px solid #84919A;
                width: 22px;
                height: 22px;
                border-radius: 100%;
            }
        
            /* active + checked*/
            .custom-checkbox.tg-round:not(:disabled):checked:active + label:hover:before {
                background-color: #50A7EA;
                border-radius: 4px;
                width: 22px;
                height: 22px;
                border-radius: 100%;
            }
            .custom-checkbox.tg-round:disabled:not(:checked) + label::before {
                border-radius: 100%;
                color: black;
                background-color: #DDE2E4;
                content: url("../../../components/UI/Icon/icons/checked-line-white.svg");
                border: none;                
            }