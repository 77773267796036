.text-field:has(.text-field__icon) {
    margin-bottom: 0;
    margin-right: 12px;
}

.text-field__icon {
    position: relative;
}

    .text-field__icon::before {
        content: '';
        color: #bdbdbd;
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        bottom: 0;
        left: 0.625rem;
        top: 50%;
        transform: translateY(-50%);
    }

    .text-field__icon .text-field__input {
        padding-left: 2rem;
    }
/* email значок */
.text-field__icon_email::before {
    content: '@';
}
/* иконка лупы */
.text-field__icon_search::before {
    width: 1rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23bdbdbd' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
}
