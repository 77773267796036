/* 1 – Изменим стили шрифтов */
/* 2 – Удалим margin в Firefox и Safari */
input[type="text"] {
    font-family: inherit; /* 1 */
    font-size: inherit; /* 1 */
    line-height: inherit; /* 1 */
    /* margin: 0; */ /* 2 */
    color: #000;
    background-color: #ffffff;
}

/* установим отступ 1rem от нижнего края элемента */
.text-field {
    /*    margin-bottom: 1rem;*/
    /* color: #252C32; */
    font-size: 16px;
    line-height: 24px;
/*    margin-bottom: 12px;*/
margin: 0px 0px 10px 0px;
}
/* стили для label */
.text-field__label {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.25rem;
    font-weight: 600;
    font-size: 16px;
}

    .text-field__label:has(~ :is([disabled],[readonly])) {
        color: #1A2024;
        opacity: 0.4000000059604645;
    }

.text-field__label-required {
    font-size: 16px;
    color: #F76659;
    margin-left: 8px;
}

/* стили для input */
.text-field__input {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-family: inherit;
    /*    font-size: 1rem;*/
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    /* color: #252C32; */
    color: #252C32;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #DDE2E4;
    border-radius: 6px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

    .text-field__input.text-field__input-error {
        border-color: red;
    }

    .text-field__input::placeholder {
        color: #252C32; /*#9AA6AC;*/
        opacity: 0.4;
    }

    .text-field__input:not(.text-field__input-error):focus {
        /* color: #212529; */
        color: #212529;
        background-color: #fff;
        border-color: #8CEEE6;
        font-size: 16px;
        outline: 0;
    }

    .text-field__input.tg:not(.text-field__input-error):focus {
        border-color: #50A7EA;
        color: #212529;
    }
    
    .text-field__input:focus {
        color: #212529;
        background-color: #fff;
        border-color: #F76659;
        font-size: 16px;
        outline: 0;
    }

    .text-field__input:not([disabled], :focus, .text-field__input-error):hover {
        /* color: #212529; */
        color: #212529;
        background-color: #fff;
        border-color: #9AA6AC;
        font-size: 16px;
        outline: 0;
    }

    .text-field__input:disabled,
    .text-field__input[readonly] {
        background-color: #F6F8F9;
        color: #B0BABF;
        border-color: #EEF0F2;
        opacity: 1;
    }

/*Стили для текста ошыбки*/
.text-field__error-text {
    margin-top: 4px;
    color: #F2271C;
    font-size: 12px;
    /*    font-family: Golos Text;*/
    line-height: 16px;
}

/*Стили для текста пояснения*/
.text-field__description-text {
    margin-top: 4px;
    color: #9AA6AC;
    font-size: 12px;
    /*    font-family: Golos Text;*/
    line-height: 16px;
}