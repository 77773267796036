* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: #fff;
    color: black;
}

.body-tg-form {
    display: flex;
    flex-direction: column;
    background-color: #F0F0F0;
    /* color: var(--tg-theme-text-color,#000, #ffffff);   */
    gap: 16px;
}

.tg-header {
    /* background-color: #F0F0F0; */
    /* color: var(--tg-theme-text-color,#000, #ffffff);   */
    /* background-color: var(#F0F0F0, #1D2733, #F4F6FA, #1D2733); */
    background-color: #F4F6FA;
}

.header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /*    align-items: center;*/
    /*    padding: 0 20px;*/
    padding: 20px;
    background: #FFF;
    /* Card Shadow */
    box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.12);
    margin-bottom: 12px;
}

.tg-header-row {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /*    align-items: center;*/
    /*    padding: 0 20px;*/
    padding: 20px;
    background: #FFF;
    /* Card Shadow */
    box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.12);
    margin-bottom: 12px;
}

.username {
    /*    margin-left: auto;*/
    color: #000;
    /* Headings / Heading 24 Semi Bold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 133.333% */
    letter-spacing: -0.456px;
}

.tg-header-name {
    /*    margin-left: auto;*/
    color: #000;
    /* Headings / Heading 24 Semi Bold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 133.333% */
    letter-spacing: -0.456px;
}

.tg-header-type {
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 133.333% */
    letter-spacing: -0.456px;
    color: #50A7EA;
    text-transform: uppercase;
}

.button {
    padding: 10px 15px;
    background: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    border: none;
    outline: none;
    cursor: pointer;
}

.form-info {
    background-color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    border-radius: 16px 16px 16px 16px;

}

.form {
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100% - 50px - 16px);
    flex-grow: 1;
    /* padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px; */
    /* padding: 16px; */
    background-color: #F6F8F9;
    /* margin-bottom:12px; */
    /*    background-color: #252C32;*/
    /*    overflow: auto !important;*/
    /* height: 100%; */
}

.form-transport {
    width: 100%;
    padding: 16px;
    background-color: white;
    /* margin-bottom: 12px; */
    /* position: relative; */
    border-radius: 16px;
    box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.12);
}

.form-drivers {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    background-color: white;
    /* margin-bottom: 12px; */
    /* position: relative; */
    border-radius: 16px;
    box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.12);
    border: 1px solid #F4F6FA;
}

.input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    background-color: #ffffff;
    color: rgb(244, 246, 250);
/*    var(--tg-theme-text-color);*/
}

.masked-input {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-family: inherit;
    /*    font-size: 1rem;*/
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #252C32;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #DDE2E4;
    border-radius: 6px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 0px;
    margin-bottom: 12px;
    outline: 0;
}

.masked-input:focus {
    border-color: #50A7EA;
}

.masked-input::placeholder {
    color: #252C32
        /*#9AA6AC*/
    ;
    opacity: 0.4;
}

/*Mobile first*/
input,
textarea,
select {
    font-size: 14px;
}

select:focus,
textarea:focus,
input:focus {
    font-size: 14px;
}

/*#Tablet upwards*/
@media (max-width: 768px) {

    input,
    textarea,
    select {
        font-size: 16px;
    }

    select:focus,
    textarea:focus,
    input:focus {
        font-size: 16px;
    }
}

/*@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus, textarea:focus, input:focus {
        font-size: 16px;
    }
}*/

.input-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: #252C32;
    /*    var(--tg-theme-text-color);*/
    padding-top: 12px;
}

.input-checkbox>.checkbox {
    margin-left: 14px;
    padding: 4px;
    background-color: #ffffff;
}


.driver-info-card>.input {
    margin-top: 8px;
    margin-bottom: 8px;
}

.select {
    padding: 10px;
    margin-bottom: 15px;
    background-color: #ffffff;
}

.block-header-tg {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 8px;
    color: rgb(244, 246, 250);
    /*    var(--tg-theme-text-color);*/
    /* color: #252C32; */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: -0.252px;
}

.driver-info-card {
    border: 0px solid gray;
    /*padding: 4px;
    padding: 12px 8px 0px 8px;*/
    margin: 10px 0;
    position: relative;
    color: #252C32;
    /*    var(--tg-theme-text-color);*/
}

.number-drivers {
    color: #6E7C87;
    font: inherit;
    font-size: 16px;
    padding-top: 12px;
}

.driver-info-ID {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    height: 20px;
    position: relative;
    /*left: 12px;*/
    top: -10px;
    /*    border: 1px solid red;*/
    /* z-index: 2; */
    /*    background-color: white;*/
    /*padding: 0 8px;*/
    /* background-color: #F0F0F0; */
    margin: 4px 0;
    font-weight: 600;
    font-size: 18px;
}
.driver-info-ID-bath {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    height: 20px;
    position: relative;
    /*left: 12px;*/
    top: -10px;
    /*    border: 1px solid red;*/
    /* z-index: 2; */
    /*    background-color: white;*/
    /*padding: 0 8px;*/
    /* background-color: #F0F0F0; */
    /* margin: 4px 0; */
    font-weight: 600;
    font-size: 18px;
    margin: 24px 0px 0px 0px;
}

.line-outside-tg {
    border-top: 1px solid #DDE2E4;
    margin: 24px 0px 24px 0px;
}

.driver-info-del {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 20px;
    position: absolute;
    right: 12px;
    top: -6px;
    /*    border: 1px solid red;*/
    z-index: 2;
    /*    background-color: white;*/
    /* padding: 16px 0px 0px 0px; */
    /* background-color: #F0F0F0; */
}

.text-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    font-weight: 600;
    /*    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -2px;*/
    /*    background-color: #143F6B;*/
    /*    color: var(--tg-theme-button-text-color);
*/
    /*    color: white;*/
    cursor: pointer;
    /*    background-color: var(--tg-theme-button-color);
*/
}


/*================= ИФЛ ====================*/
.type-selector {
    /*    border: 1px solid gray;*/
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border: 1px solid #50A7EA;
    border-radius: 4px;
    margin-bottom: 8px;
}

.type-item {
    padding-top: 8px;
    padding-bottom: 8px;
    border: 0px solid #DDE2E4;
    color: #6E7C87;
    background-color: #fff;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.type-item.active {
    background-color: #50A7EA;
    border-color: white;
    color: white;
}

.type-item:not(active) {
    color: #50A7EA;
}

.type-selector-ifl {
    /*    border: 1px solid gray;*/
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border: 0px solid #DDE2E4;
    border-radius: 4px;
}

.type-item-ifl {
    padding-top: 8px;
    padding-bottom: 8px;
    border: 0px solid #DDE2E4;
    color: #6E7C87;
    background-color: #fff;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
}

.type-item-ifl.active {
    background-color: #50A7EA;
    border: 1px solid #50A7EA;
    border-radius: 4px;
    color: white;
    gap: 2px;
}

.type-item-ifl:not(active) {
    border: 1px solid #50A7EA;
    border-radius: 4px;
    color: #50A7EA;
}

.block-header {
    font-weight: 600;
    font-size: 16px;
}

.input-radiobutton {
    display: flex;
    /*    flex-wrap: nowrap;*/
    flex-direction: column;
    justify-content: flex-start;
    /*    align-items: center;*/
    font-size: 16px;
    /*    flex: 2 1;*/
}

.input-radiobutton>.radiobutton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    /*        flex: 2;*/
}

    .input-radiobutton > .input-summ {
        display: flex;
        flex-direction: row;
        /*        justify-content: center;*/
        align-items: center;
        /*        flex: 1;*/
        width: 100%;
        padding: 10px;
        margin-bottom: 15px;
        background-color: #ffffff;
        color: rgb(244, 246, 250);
/*        var(--tg-theme-text-color);*/
    }

.input-radiobutton>.icon {}

.input-selector {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin: 10px 0;
    /*    padding:10px 0px;*/
}

.input-selector>select {
    padding: 10px;
    margin: 0 0 15px;
    box-shadow: none !important;
    border-width: 1px !important;
    border-color: rgba(118, 118, 118, 0.3) !important;
}

.input-selector>select:active {
    box-shadow: none !important;
    border-width: 0px !important;
    border-color: rgba(118, 118, 118, 0.3) !important;
}

.small-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    font-size: 16px;
}

.label-years {
    flex: 2;
}

.years {
    flex: 1;
    margin: 0;
}

.availability-select {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /*margin: 0 0 15px;*/
    padding: 10px 0;
}

.profile-menu-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: #FFF;
    color: #252C32;
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    /* Card Shadow */
    box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.12);
    margin-bottom: 2px;
    border-radius: 12px;
}

.profile-menu-line:not(:first-child) {
    cursor: pointer;
}

.profile-menu-line div:last-child {
    color: #252C32;
    text-align: right;
    /* Headings / Heading 18 Semi Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: -0.252px;
}

.profile-menu-line svg {
    margin-right: 12px;
}

.profile-menu-line-balance {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: #FFF;
    color: #252C32;
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    /* Card Shadow */
    box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.12);
    margin-bottom: 2px;
    border-radius: 12px;
}

.balance-bold-text {
    color: var(--black-white-black, var(--Colors-Monochrome-1000, #000));
    text-align: right;
    /* Headings / Heading 36 Bold */
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    /* 133.333% */
    letter-spacing: -0.792px;
}

.balance-text {
    color: var(--Mid-Gray-Mid-Gray-2, #6E7C87);
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
}

.tg-background {
    height: 100%;
    /*    background: #F0F0F0;*/

    background: #F4F6FA;
    /*    background: #DDE2E4;*/
}

.friend-container {
    margin-bottom: 16px;
}

/*    .friend-container .friend-line:nth-child(3) {
        color: red;
        border-bottom: 2px solid red;
    }*/

.friend-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px;
    margin: 0px 16px;
    /* text-align: center;
    padding: 16px 16px; */
    background: #FFF;
    color: #252C32;
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    border-radius: 12px;
    /* margin: 0px 16px; */
}

.friend-line.bold {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.252px;
}

.friend-line:first-child {
    padding-top: 16px;
}

.friend-line:last-child {
    padding-bottom: 16px;
}

.tg-delimitator {
    height: 16px;
    background: #FFF;
    position: relative;
}

.tg-delimitator::before {
    top: 16px;
    position: absolute;
    content: "";
    width: calc(100% - 32px);
    left: 16px;
    background: #DDE2E4;
    height: 1px;
}

.tg-text-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background: #FFF;
}

.tg-text-button-name {
    color: var(--Blue, #50A7EA);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    cursor: pointer;
}

.tg-text-button-name svg {
    margin-left: 8px;
}

.adddrivertext {
    color: #50A7EA;
    margin-left: -2px;
}

.insurance-small-data-container {
    display: flex;
    padding: 16px;
    /* margin: 16px 16px; */
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    background: #F6F8F9;
    color: #6E7C87;
    /* UI / UI Text 14 Reg */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: -0.084px;
    cursor: pointer;
}

.insurance-small-info-line {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.insurance-small-info-line div:first-child:not(.ifl-tg-option > div) {
    width: calc((100% - 24px) / 2);
    margin-right: 24px;
}

.insurance-small-info-line div:last-child:not(.ifl-tg-option > div) {
    width: calc((100% - 24px) / 2);
}

.small-data-tg {
    margin-top: 16px;
}

.referal-link-container {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 10px;
    background: #DDE3ED;

    /*    background: #FFF;*/
    /* Card Shadow */
    box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.12);
    color: #6E7C87;
    /* Running Text / Paragraph 14 Reg */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.084px;
}

.ifl-tg-option {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    margin: 0px !important;
}

.ifl-tg-option>div {
    margin: 0px !important;
    padding: 0px 8px;
    border: solid 1px green;
    border-radius: 6px;
}

.tg-ticket-menu-container {
    display: flex;
    flex-direction: row;
    /*    flex-wrap: wrap;*/
    /*flex-wrap: nowrap;*/
    white-space: nowrap;
    overflow-y: scroll;
    /* padding-bottom: 16px; */
    padding-left: 24px;
    padding-right: 24px;
    /*    margin-left: 24px;*/
}

.tg-ticket-menu-container-new {
    display: flex;
    flex-direction: row;
    /*    flex-wrap: wrap;*/
    /*flex-wrap: nowrap;*/
    white-space: nowrap;
    /*    border: 1px solid #3C464E;*/
    /*    overflow-y: scroll;*/
    /* padding-bottom: 16px; */
    /*        border-radius: 6px;*/
    /*overflow: hidden;*/

    color: var(--Dark-Gray-Dark-Gray-4, #3C464E);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
}

.tg-ticket-menu-item-new:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    /*        border: 1px solid var(--Blue, #3C464E);*/
}

.tg-ticket-menu-item-new:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    /*        border: 1px solid var(--Blue, #3C464E);*/
}

.tg-ticket-menu-item {
    border: 1px solid var(--Blue, #50A7EA);
    padding: 10px 16px;
    color: var(--Blue, #50A7EA);
    background-color: #ffffff;
}

.tg-ticket-menu-item.active {
    /*        background-color: lightpink;*/
    color: white;
    background-color: var(--Blue, #50A7EA)
}

.tg-ticket-menu-item-new {
    border: 1px solid rgba(60, 70, 78, 0.5);
    padding: 10px 16px;
    width: 50%;
    color: #3C464E;
    background-color: #ffffff;
}

.tg-ticket-menu-item-new.active {
    border-color: var(--Blue, #50A7EA);
    color: white;
    width: 50%;
    background-color: var(--Blue, #50A7EA)
}


.css-1rh70hh-control:hover {
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #50A7EA !important;
}

.tg-saved-offer-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px 24px;
    border-radius: 6px;
    border: 1px solid #DDE2E4;
    /*    margin-bottom: 24px;*/
    gap: 8px;
    margin-top: 16px;
}

.tg-company-offers {
    /* display: flex; */
    /* flex-direction: row; */
    /* flex-wrap: wrap; */
    gap: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-y: scroll;
    padding-left: 16px;
    padding-right: 16px;
}

.tg-offer-item {
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #DDE2E4;
    width: 50%;
    /* margin: 0px 0px 0px 16px; */
    /* min-height: 200px; */
    min-width: 158px;
    margin-top: 16px;
    position: relative;
}

.tg-offer-item-ifl-tg {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #DDE2E4;
    width: 240px;
    /* margin: 0px 0px 0px 16px; */
    /* min-height: 200px; */
    /* margin-top: 16px; */
    /* border: 1px solid red; */
}

.tg-insurance-offer-osago-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-wrap: wrap;
    padding: 16px;
    background-color: white;
}

.tg-insurance-offer-ipoteka-container {
    display: flex;
    flex-direction: column;
    /* gap: 16px; */
    flex-wrap: wrap;
    padding: 16px;
    background-color: white;
}

.tg-offer-item-osago-group {
    display: flex;
    border: 1px solid #EEF0F2;
    border-radius: 6px;
    justify-content: space-between;
    /*    margin: 16px 0px;*/
    padding: 8px;
    text-align: center;
}

.tg-offer-item-osago-group-ticket {
    display: flex;
    flex-direction: row;
    background-color: #EEF0F2;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 600;
    justify-content: space-between;
    padding: 16px;
    background-color: white;
    border: 1px solid #DDE2E4;
    align-items: center;
}

.tg-offer-item-ipoteka-group-ticket {
    display: flex;
    flex-direction: column;
    background-color: #EEF0F2;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 600;
    justify-content: space-between;
    padding: 16px 0px;
    background-color: white;
    /* border: 1px solid #DDE2E4; */
    /* align-items: center; */
}

.tg-ifl-offer-ifl {
    display: flex;
    flex-direction: column;
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    gap: 20px;
    margin-bottom: 20px;
}

.tg-ifl-offer-ifl-ticket {
    display: flex;
    flex-direction: row;
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    gap: 12px;
    overflow-y: scroll;
    flex-wrap: nowrap;
}

.radio-button-tg {
    border: 1px solid red;
}

.object-container-tg {
    background-color: white;
    border-radius: 12px;
    padding: 8px;
    margin: 12px 0px;
}

.object-name-tg {
    color: var(--black-white-black, #000);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    padding-left: 8px;
}

.ticket-data-summary-info-tg {
    background-color: white;
    padding: 16px;
    border-radius: 12px;
}

.group-button-tg {
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: white;
    padding: 16px;
    margin-top: 12px;
    border-radius: 12px 12px 0px 0px;
}

.container-step-three-tg {
    display: flex;
    flex-direction: column;
    background-color: #F6F8F9;
    /* padding: 16px; */
}

.input-radio-group-tg {
    background-color: white;
    border-radius: 12px;
    margin: 24px 0px 0px 0px;
}

.input-radio-group-doc-tg {
    background-color: white;
    border-radius: 12px;
    margin: 12px 0px 0px 0px;
    padding: 16px;
    gap: 8px;
}

.group-containers-tg {
    background-color: white;
    border-radius: 12px;
    padding: 16px;
    margin: 12px 0px 0px 0px;
    gap: 24px;
}
/* .group-container-tg {
    background-color: white;
    border-radius: 12px;
    padding: 8px 16px 8px 16px;
    margin: 12px 0px 0px 0px;
    border: 1px solid #B0BABF;
} */

.date-container-tg {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 16px 0px 0px 0px;
}

.input-radio-legend-tg {
    margin: 0px;
    padding: 0px 0px 12px 0px;
}

.input-radio-tg {
    display: flex;
    padding: 0px 0px 12px 0px;
}

.label-input-tg {
    margin: 0px 0px 4px 0px;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.176px;
}

.adres-delivery-tg {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
}

.tg-ticket-offer-list-tg {
    background-color: white;
}

.company-name-ticket-tg {
    color: #252C32;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.456px;
    margin-left: 16px;
    margin-top: 32px;
    display: flex;
    gap: 8px;
    flex-direction: row;
    align-items: center;
}

.full-kasko-header-tg {
    position: absolute;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    border-radius: 4px 4px 4px 0px;
    background: #F8C51B;
    /* bottom: 16px; */
    /* right: 9px; */
    /* width: 60%; */
    top: -12px;
    left: -1px;
    padding: 3px 6px;
}

.offer-summ-tg {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    display: flex;
}

.franchizes-container-tg {
    display: flex;
    flex-direction: column;
}

.franschize-size-row-tg {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6px 0px 6px 12px;
}

.franschize-size-row-tg:hover {
    border-radius: 6px;
    background: rgba(80, 167, 234, 0.15);
}

/* .franschize-size-row-tg.selected {
    background-color: rgb(147, 186, 86);
} */

.small-summ-tg {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    display: flex;
}

.item-summ-tg {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
}

.check-container-tg {
    position: absolute;
    /* left: 137px; */
    right: 8px;
}

.check-container-osago-tg {
    position: absolute;
    right: 32px;
}

.check-container-ipoteka-tg {
    position: absolute;
    right: 32px;
}

.check-container-second-tg {
    position: relative;
}

.group-info-container-tg {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 12px;
    margin: 12px 0px;
    border-radius: 12px;
    background-color: white;
    flex-grow: 1;
}

.group-info-tg {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.group-text-tg {
    display: flex;
    flex-direction: row;
    gap: 6px;
    text-align: center;
    align-items: center;
}

.seperate-line-tg {
    border: 1px solid #DDE2E4;
}

.statistics-text-tg {
    color: #50A7EA;
    text-decoration: none;
}

.tg-selected {
    /* border: 1px solid #50A7EA; */
    border-radius: 8px;
    border: 4px solid #50A7EA;
    background: #FFF;
    /* Card Shadow */
    box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.12);
}

.selected-tg {
    border: 1px solid yellow;
}

.offer-item-osago-summ-tg {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.offer-item-ipoteka-summ-tg {
    display: flex;
    flex-direction: row;
    gap: 8px;
    color: var(--black-white-black, #000);
    text-align: center;
    /* UI Small / UI Text 12 Reg */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
}

.offer-item-osago-name-tg {
    color: var(--Dark-Gray-Dark-Gray-2, #252C32);
    /* Headings / Heading 24 Semi Bold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 133.333% */
    letter-spacing: -0.456px;
    display: flex;
    justify-content: row;
    gap: 8px;
    align-items: center;
}

.offer-item-ipoteka-name-tg {
    color: var(--Dark-Gray-Dark-Gray-2, #252C32);
    /* Headings / Heading 24 Semi Bold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 133.333% */
    letter-spacing: -0.456px;
    margin-bottom: 16px;
    gap: 8px;
    display: flex;
    flex-direction: row;
}

.summ-text-tg {
    color: var(--black-white-black, #000);
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
}

.summ-osago-tg {
    color: var(--black-white-black, #000);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
}

.tg-offer-item-osago-group-tickettg-selected {
    display: flex;
    flex-direction: row;
    border: 1px solid #50A7EA;
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
}

/* .tg-offer-item-ipoteka-group-tickettg-selected {
    display: flex;
    flex-direction: row;
    border: 1px solid #50A7EA;
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
} */
.tg-offer-item-osago-group-ticketdeclined {
    display: flex;
    flex-direction: row;
    border: 1px solid red;
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    background-color: #FFB2B2;
}

/* .tg-offer-item-ipoteka-group-ticketdeclined {
    display: flex;
    flex-direction: row;
    border: 1px solid red;
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    background-color: #FFB2B2;
} */
.string-container-tg {
    border: 1px solid red;
}

.big-string-container-tg {
    border: 1px solid blue;
}

.tg-ticket-first-menu {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    gap: 10px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: white;
    color: var(--Dark-Gray-Dark-Gray-2, #252C32);
    text-align: center;
    /* UI Small / UI Text 12 Reg */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    margin-bottom: 12px;
}

.tg-menu-item {}

.tg-menu-item.tg-selected-menu {
    font-weight: 600;
}

.loader-secondary {
    width: 100%;
    height: calc(100% - 50px - 12px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: #F6F8F9;
}

.form-tg {
    background-color: white;
    padding: 16px;
    /* position: relative; */
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.12);
}

.first-form-tg {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100% - 50px - 16px);
    flex-grow: 1;
    /* padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px; */
    /* padding: 16px; */
    background-color: #F6F8F9;
    /* margin-bottom:12px; */
    /*    background-color: #252C32;*/
    /*    overflow: auto !important;*/
    /* height: 100%; */
}

.tg-offer-item-osago-group-tickettg-selected {
    display: flex;
    flex-direction: row;
    border: 1px solid #50A7EA;
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
}

.confirm-page-tg {
    display: flex;
    background-color: white;
    width: 360px;
    padding: 80px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    height: 100%;
    width: 100%;
    border-radius: 12px;
}

.big-checkbox-container-tg {
    border: 5px solid #50A7EA;
    border-radius: 100%;
    display: flex;
    width: 64px;
    height: 64px;
    justify-content: center;
    align-items: center;
}

.confirm-page-primary-tg {}

.confirm-page-text-tg {
    color: var(--Dark-Gray-Dark-Gray-4, #3C464E);
    text-align: center;
    /* Running Text / Paragraph 14 Reg */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.084px;
}

.confirm-page-text-done-tg {
    color: var(--Dark-Gray-Dark-Gray-2, #252C32);
    /* Headings / Heading 24 Semi Bold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 133.333% */
    letter-spacing: -0.456px;
    text-align: center;
}

.confirm-page-button-container-tg {
    display: flex;
    margin-top: 12px;
    background-color: white;
    padding: 16px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    justify-content: center;
}

.confirm-page-button-tg {
    width: 328px;
}

.confirm-page-container-tg {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.group-text-polis-tg {
    display: flex;
    justify-content: space-between;
}

/* .offer-item-ipoteka-summ-tgdeclined {
    display: flex;
    flex-direction: row;
    border: 1px solid red;
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    background-color: #FFB2B2;
} */
/* .offer-item-ipoteka-summ-tgtg-selected {
    display: flex;
    flex-direction: row;
    border: 1px solid #50A7EA;
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
} */
.container-ipoteka-tgdeclined {
    display: flex;
    flex-direction: column;
    border: 1px solid red;
    /* gap: 8px; */
    border-radius: 12px;
    padding: 16px;
    background-color: #FFB2B2;
}

.container-ipoteka-tgtg-selected {
    display: flex;
    flex-direction: column;
    border: 1px solid #50A7EA;
    /* gap: 8px; */
    border-radius: 12px;
    padding: 16px;
}

.vertical-line-tg {
    display: flex;
    justify-content: center;
    border-left: 1px solid #DDE2E4;
    height: 40px;
    margin: 0px 16px;
}

.second-container-ipoteka-tg {
    display: flex;
    flex-direction: row;
}

.container-ipoteka-tg {
    display: flex;
    flex-direction: column;
    border: 1px solid #DDE2E4;
    /* gap: 8px; */
    border-radius: 12px;
    padding: 16px;
}

.remainder-summ-ipoteka-tg {
    color: var(--black-white-black, #000);
    /* UI Small / UI Text 12 Reg */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
}

.offer-item-ipoteka-summ-container-tg {}

.first-container-ipoteka-tg {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.typeInsurance-property-ipoteka-tg {
    color: var(--black-white-black, #000);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    border-radius: 4px;
    background: var(--Purple-P200, #F5D2FE);
    padding: 0px 8px;
}

.typeInsurance-life-ipoteka-tg {
    color: var(--black-white-black, #000);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    padding: 0px 8px;
    border-radius: 4px;
    background: var(--Green-G200, #BBFBD0);
}

.typeInsurance-title-ipoteka-tg {
    color: var(--black-white-black, #000);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    padding: 0px 8px;
    border-radius: 4px;
    background: var(--Primary-PR200, #D7EDFF);
}

.icon-upload-tg {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.icon-upload-container-tg {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 6px 0px;
}

.name-doc-upload-tg {
    /* display: flex; */
    /* flex-direction: row; */
    /* gap: 8px; */
    /* display: block; */
}

.color-asterix-tg {
    display: inline;
    color: red;
    margin-left: 8px;
}

.item-group-ifl-tg {
    display: flex;
    flex-direction: column;
    width: 240px;
    gap: 2px;
}

.full-ifl-header-tg {
    display: flex;
    /* padding: 10px 12px; */
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 6px;
    background: white;
}

.company-name-ticket-ifl-tg {
    display: flex;
    align-items: center;
    width: 328px;
    gap: 8px;
    color: var(--Dark-Gray-Dark-Gray-2, #252C32);
    /* Headings / Heading 24 Semi Bold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 133.333% */
    letter-spacing: -0.456px;
}

.container-icon-text-ifl-tg {
    display: flex;
    flex-direction: row;
    gap: 8px;
    color: var(--black-white-black, #000);
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    align-items: center;
}

.text-summ-ifl-tg {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding-left: 28px;
}

.summ-ifl-tg {
    color: var(--black-white-black, #000);
    /* UI Small / UI Text 12 Semi Bold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 133.333% */
}

.container-group-ifl-tg {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.summ-row-ifl-tg {
    color: var(--black-white-black, #000);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
}

.container-price-ifl-tg {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.ifl-container-first-tg {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.check-container-ifl-tg {
    margin-left: 32px;
}

.check-container-ifl-tg>div>label::before {
    margin: 0px;
    padding: 0px;
}

.check-container-ipoteka-tg>div>label::before {
    margin: 0px;
    padding: 0px;
}


.offerComment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    position: relative;
}

.offerComment.commentFirst {
    padding: 12px 16px;
    margin: 16px 20px;
    border-radius: 8px;
    border: 1px solid var(--Yellow-Y200, #FEF99F);
    background: var(--Yellow-Y100, #FFFCC2);
    /* Card Shadow */
    box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.12);
}

.offerCommentHeader {
    color: var(--black-white-black, #000);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
}

.offerCommentText {
    /*    display: -webkit-box;*/
    /*    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;*/
    align-self: stretch;
    overflow: hidden;
    color: var(--black-white-black, #000);
    text-overflow: ellipsis;
    /* Running Text / Paragraph 14 Reg */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.084px;
    /*    min-height: 80px;*/
    max-height: 80px;
}

.offerCommentText:has(.collapsed) {
    /*        height: 80px;*/
}

.offerCommentText:not(.collapsed) {
    max-height: 100%;
    /*        min-height: 80px;*/
}

.offerCommentButton {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.084px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* gap: 4px; */
}

.offerCommentButton.commentFirst,
.offerCommentButton.commentFirst>svg>path {
    color: var(--Yellow-Y700, #D29404);
    stroke: var(--Yellow-Y700, #D29404);
}

.offerCommentButton:not(.commentFirst),
.offerCommentButton:not(.commentFirst)>svg>path {
    color: var(--Blue, #50A7EA);
    stroke: var(--Blue, #50A7EA);
}

.offerCommentButton.commentFirst,
.offerCommentButton.commentFirst>svg>path {
    color: var(--Yellow-Y700, #D29404);
    stroke: var(--Yellow-Y700, #D29404);
}

.offerCommentButton:not(.commentFirst),
.offerCommentButton:not(.commentFirst)>svg>path {
    color: var(--Blue, #50A7EA);
    stroke: var(--Blue, #50A7EA);
}

.offerCommentButton>svg {
    height: 18px;
}

.commentIcon {
    position: absolute;
    /* z-index: 1; */
    top: 0;
    right: 0;
}

.icon-clip>svg>path {
    /* color: #50EA7E; */
    stroke: #50EA7E;
}

.text-container-tg {
    background-color: #FFF;
    display: flex;
    padding: 16px;
    margin-bottom: 12px;
    border-radius: 12px;
}

.buttons-cells-first-tg {
    display: flex;
    margin-top: 12px;
    background-color: white;
    padding: 16px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    justify-content: center;
    width: 100%;
}

.conteiner-nokp-tg {
    display: flex;
    flex-direction: column;
}

.container-text-button-tg {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.bank-container {
    margin-bottom: 8px;
}

.datapicker-tg {
    border: 1px solid #DDE2E4;
    border-radius: 6px;
    padding: 8px;
    outline: none;
}

.check-box-civil-tg {
    padding: 4px 0px 12px 0px;
}

.container-select-bank-tg {
    z-index: 3;
    /* padding-bottom: 16px; */
}

.input-checkbox-container-tg {
    /* padding-bottom: 16px; */
}

.container-button-polis {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    gap: 12px;
}

.new-polis-description {
    padding: 16px;
    background-color: #fff;
}

.name-text-polis {
    color: var(--Dark-Gray-Dark-Gray-2, #252C32);
    /* Headings / Heading 18 Semi Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: -0.252px;
    padding-bottom: 12px;
}

.text-polis {
    color: var(--Dark-Gray-Dark-Gray-2, #252C32);
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
}

.text-polis-second {
    color: var(--Dark-Gray-Dark-Gray-2, #252C32);
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    padding-top: 35px;
}

.form-polis {
    background-color: #F0F0F0;
}

.icon-text-polis {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}


/*new menu styles*/
.tg-main-menu-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 16px;
}

.tg-profile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 12px;
    border-radius: 16px;
    background-color: #ffffff;
    /* Card Shadow */
    box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.12);
}

.tg-profile-row {
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: 100%;
    border-radius: 16px;
    background: var(--black-white-white, #FFF);

}

.tg-profile-img {
    display: flex;
    background: #F0F3F8;
    gap: 10px;
    border-radius: 100px;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
}

.tg-profile-info {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.011em;
    text-align: left;
}

.tg-services {
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
    /*    flex-shrink: 1;*/
}

.tg-main-menu-button {
    display: flex;
    flex-direction: row;
    width: calc(50% - 6px);
    background-color: #ffffff;
    border-radius: 16px;
    padding: 16px;
    gap: 12px;
    align-items: center;
    flex: 1 0 0;
    background: var(--black-white-white, #FFF);
    /* Card Shadow */
    box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.12);
}

.icon-car-tg {
    display: flex;
    width: 36px;
    height: 36px;
    align-items: center;
}

.tg-in_devel-mode {
    position: relative;
}

.tg-in-devel-label {
    display: flex;
    gap: 10px;
    z-index: 10;
    position: absolute;
    right: 0px;
    top: -3px;
    background-color: #BBF6F4;
    padding: 2px 4px 2px 4px;
    border-radius: 8px;
    color: #3C464E;
    font-size: 12px;
    font-weight: 400;
}

/* .custom-popover {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    gap: 12px;
}

    .custom-popover > .popover-text {
        background: rgba(0, 0, 0, .6) !important;
        color: #ffffff !important;
        z-index: 11;
        text-align: center;
    } */

.modal-menu-container {

    z-index: 50;
    background-color: transparent;
    display: none;
}

.modal-menu-container.active {
    display: flex;
    position: absolute;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    /*        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -webkit-transition: background-color 1000ms linear;
        -moz-transition: background-color 1000ms linear;
        -o-transition: background-color 1000ms linear;
        -ms-transition: background-color 1000ms linear;
        transition: background-color 1000ms linear;*/
}

.modal-menu-items-container {
    display: flex;
    padding: 16px 16px 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px 16px 0px 0px;
    background: #F4F6FA;
}

.modal-menu-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    color: var(--black-white-black, var(--Colors-Monochrome-1000, #000));
    text-align: center;
    /* Headings / Heading 18 Semi Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: -0.252px;
}

.modal-menu-header-text-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: var(--Mid-Gray-Mid-Gray-2, #6E7C87);
    /* UI Small / UI Text 12 Reg */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    gap: 8px;
}

.modal-menu-header-text {
    gap: 8px;
    display: flex;
    flex-direction: column;
}

.profile-text-tg {
    color: var(--black-white-black, var(--Colors-Monochrome-1000, #000));
    /* Running Text / Paragraph 16 Reg */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
}

.profile-balance-tg {
    color: var(--Mid-Gray-Mid-Gray-2, #6E7C87);
    /* UI Small / UI Text 12 Reg */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    align-items: center;
}

.icon-ruble-tg {
    border-radius: 100px;
    background: var(--Blue, #50A7EA);
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.body-profile-tg {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0px 16px 16px 16px;
}

.services-text {
    color: var(--black-white-black, var(--Colors-Monochrome-1000, #000));
    /* Headings/Heading 14 Semi Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}

/*MainMenu new button*/
.buttonUploadContainer {
    display: flex;
    background-color: var(--teal-t-600, #50A7EA);
    border: 1px solid var(--teal-t-600, #50A7EA);
    color: #ffffff;
    width: 100%;
    flex-direction: row;
    border-radius: 6px;
    padding: 10px 16px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
}

.button_spinner {
    position: absolute;
    right: 16px;
}

.upload-files-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 16px 16px 16px;
    background-color: transparent;
    gap: 12px;
    min-height: calc(100vh - 64px);
}

.upload-files-container-block {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    gap: 12px;
}

.buttonUploadContainer.tg-blue {
    background-color: var(--teal-t-600, #50A7EA);
    border: 1px solid #50A7EA;
    color: #ffffff;
}

.buttonUploadContainer.tg-white {
    background-color: var(--black-white-white, #FFF);
    color: black;
    border-radius: 6px;
    border: 1px solid var(--Light-Gray-Light-Gray-2, #DDE2E4);
}

.buttonUploadContainer.tg-white>svg>path {
    stroke: black;
}

.form-upload-file-tg {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.icon-text-file-tg {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    gap: 8px;
}

.container-upload-file-tg {
    display: flex;
    padding: 12px 16px 12px 12px;
    align-items: center;
    gap: 12px;
    border-radius: 16px;
    background: var(--black-white-white, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.12);
    width: 100%;
}

.text-info-upload-tg {
    padding-left: 24px;
}

.icon-file-upload-tg svg {
    width: 64px;
    height: 88px;
    

}

img {
    object-fit: contain;
}

.icon-file-upload-tg > img {
/*    display: flex;*/
    object-fit: contain;
/*    width: 100%;*/
    height: 100%;
}

.container-info-img-tg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /*    width: 100%;*/

    width: 100%;
    /*height: 100%;*/
}

.icon-text-back-tg {
    display: flex;
    padding-bottom: 4px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    color: var(--black-white-black, var(--Colors-Monochrome-1000, #000));
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
}

.buttonUploadContainer.button-disabled {
    background-color: #E5E9EB !important;
    border: 1px solid #E5E9EB;
    color: #252C32 !important;
}

.buttonUploadContainer.button-disabled>svg>path {
    stroke: #252C32 !important;
}

.tg-button-horizontal {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
}


.remove-btn {
    margin-right: 0.5rem;
}

/*Для анимации удаления итема используется в файлах пока но может будет еще где*/
.item-enter {
    opacity: 0;
}

.item-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
}

.item-exit {
    opacity: 1;
}

.item-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-out;
}

.label-input-year-tg {
    padding-top: 12px;
    padding-bottom: 4px;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.176px;
}

.masked-disabled-input {
    background-color: #F6F8F9 !important;
    color: #B0BABF;
    border-color: #EEF0F2;
    opacity: 1;
}
.personal-data-question {
    display: flex;
    flex-direction: row;
    gap: 8px;
    text-align: center;
    background-color: #FFF;
    padding: 16px;
    border-radius: 16px;
    margin-bottom: 16px;
    border: 1px solid #F4F6FA;
    box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.12);
}
.label-policy-privacy-tg {
    text-align: left;
    font-size: 13px;
    font-family: Inter;
}